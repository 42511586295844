.page-header {
  margin-bottom: 10px;

  @include sp-screen {
    margin-bottom: 0;
    border-bottom: 0;
  }

  .header.content {
    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 200px);
    max-width: 1080px;
    padding: 25px 0 0;

    @include sp-screen {
      width: calc(100% - 32px);
      max-width: none;
      height: 52px;
      margin: 0 auto;
      padding: 0;
      flex-direction: row-reverse;
      align-items: center;
    }

    .nav-toggle {
      @include sp-screen {
        position: relative;
        top: 0;
        left: 0;
        width: 24px;
        height: 17px;

        &:before {
          display: none;
        }

        span {
          width: 24px;
          height: 1px;
          display: block;
          background: $text-black;
          margin: 0;
          position: static;
          overflow: visible;
          margin-top: 8px;
          transition: 0.3s;

          &:before {
            content: "";
            width: 24px;
            height: 1px;
            display: block;
            background: $text-black;
            position: absolute;
            top: 0;
            transition: 0.3s;
          }

          &:after {
            content: "";
            width: 24px;
            height: 1px;
            display: block;
            background: $text-black;
            position: absolute;
            bottom: 0;
            transition: 0.3s;
          }
        }
      }
    }

    .logo {
      margin: 0;

      &:after {
        @include sp-screen {
          content: "MOMO NATURAL FURNITURE AND SOFA";
          display: inline-block;
          width: 180px;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.2;
          letter-spacing: 1.55px;
          color: $text-black;
        }
      }

      img {
        width: auto;
        height: 42px;

        @include sp-screen {
          display: none;
        }
      }
    }

    .header {
      justify-content: flex-end;
      padding: 0 0 25px;
      margin: 0;
      width: auto;
      max-width: none;

      li {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        max-height: 16px;

        a {
          color: $text-deep-gray;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 1.85px;
          padding: 0 7.5px;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            border-right: 1px solid $text-gray;
            display: block;
            width: 100%;
            height: 10px;
            right: 0;
            top: 3px;
          }
        }
      }

      .minicart-wrapper {
        display: flex;
        align-items: center;
        margin-left: 0;
        max-height: 16px;

        .action.showcart {
          display: flex;

          &:before {
            display: none;
          }

          .text {
            position: initial;
            color: $text-deep-gray;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1.85px;
            padding-left: 7.5px;
            width: 100%;
            height: 100%;
            padding-top: 1px;
          }

          .counter {
            display: none;
            justify-content: flex-end;
            background: transparent;
            min-width: auto;
            width: 100%;
            height: auto;
            line-height: normal;
            border-radius: 0;
            margin: 0;
            padding: 0;

            .counter-number {
              font-size: 12px;
              font-weight: 600;
              letter-spacing: 1.85px;
              white-space: nowrap;
              color: $text-deep-gray;
            }
          }
        }
      }
    }
  }
}

.nav-sections {
  background: transparent;
  margin-bottom: 0px;

  @include pc-screen {
    &-items {
      width: calc(100% - 200px);
      max-width: 1080px;
      margin: 0 auto;

      .nav-sections-item-content {
        .navigation {
          background: transparent;

          .ui-menu {
            padding: 0;

            // 親リスト
            .level0 {
              margin: 0 32px 0 0;

              &:hover {
                .level-top {
                  span {
                    opacity: 0.5;
                  }
                }
              }

              .level-top {
                padding: 0;
                border: 0;

                span {
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 1.2px;
                }
              }

              // 子リスト
              .submenu {
                display: flex;
                border: 0;
                padding: 16px;
                box-shadow: none;
                background: rgba(0, 0, 0, 0.5);

                &-items {
                  width: 150px;
                  margin-bottom: 0;
                  margin-right: 32px;

                  &:last-child {
                    margin-right: 0;
                  }

                  div {
                    a {
                      border: 0;
                      padding: 0;
                      color: $text-white;

                      &:hover {
                        background: transparent;
                      }
                    }
                  }

                  .submenu-title {
                    padding-bottom: 11.5px;

                    a {
                      font-size: 14px;
                      font-weight: 600;
                      letter-spacing: 0.36px;
                      border-bottom: 1px solid $text-white;
                    }
                  }

                  .submenu-item {
                    padding-bottom: 8px;

                    &:last-child {
                      padding-bottom: 0;
                    }

                    a {
                      font-size: 12px;
                      font-weight: 400;
                      letter-spacing: 1px;
                    }
                  }
                }
              }
            }

            // algolia検索
            .block-search {
              padding-left: 0;
              width: 170px;
              height: 53px;
              display: flex;
              align-items: center;
              z-index: auto;

              .block-content {
                width: 100%;

                #search_mini_form {
                  #algolia-searchbox {
                    .algolia-autocomplete {
                      #search {
                        padding: 0;
                        border: 0;
                        border-bottom: 1px solid $text-gray;
                        font-family: minion-pro, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E",
                          "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
                      }
                    }

                    .magnifying-glass {
                      width: 15px;
                      height: 15px;
                      right: 0;
                      display: inline-block;
                      position: absolute;
                      background: url("../images/search_icon.png");
                      background-size: contain;
                      background-position: center;
                      background-repeat: no-repeat;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.navigation {
  @include sp-screen {
    display: none;
  }
}

.nav-sections-item-title {
  @include sp-screen {
    display: none;
  }
}

.nav-sections {
  @include sp-screen {
    transition: top 0.2s;
    top: 52px;
    left: auto;
    width: 100%;
    opacity: 0;
    z-index: -1;
  }

  .nav-sections-items {
    .nav-sections-item-content {
      @include sp-screen {
        float: none;
        margin-left: 0;
        margin-top: 0;
        padding: 0;
      }
    }
  }
}

//ハンバーガーメニューを開いた時
.nav-open {
  .page-wrapper {
    left: auto;
  }

  .nav-toggle {
    &:after {
      background: none;
    }
  }

  // ハンバーガーメニュー「三」→「X」
  .page-header {
    .header {
      .nav-toggle {
        span {
          background: transparent;
          transition: 0.3s;

          &:before {
            top: 8px;
            transform: rotate(35deg);
            transition: 0.3s;
          }

          &:after {
            bottom: 8px;
            transform: rotate(-35deg);
            transition: 0.3s;
          }
        }
      }
    }
  }

  .nav-sections {
    box-shadow: none;
    height: auto;
    opacity: 1;
    top: 52px;
    bottom: 0;
    margin-bottom: 0;
    overflow-y: scroll;
    transition: 0.3s;

    .navigation-sp {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        opacity: 0.93;
        z-index: -1;
      }

      .items {
        margin-bottom: 0;

        .item {
          border-top: 1px solid $static-border-gray;
          margin-bottom: 0;

          .text-box {
            a {
              display: block;
            }

            .text {
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 1px;

              display: block;
              padding: 16px;
            }
          }

          .section__top {
            &:before {
              content: "";
              @include arrow-icon($tf: 45deg, $w: 5px, $h: 5px, $r: 16px);
            }
          }

          .active {
            &:before {
              transform: rotate(135deg);
            }
          }
        }
      }

      .menu-wrapper-sp {
        display: flex;
        flex-direction: column;
        margin: 0 auto 58px;
        position: relative;

        .menu-bottom-background {
          position: absolute;
          bottom: 0;

          &:after {
            content: "";
            position: fixed;
            display: block;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.67;
            bottom: auto;
          }
        }

        .level1-item {
          .text-box {
            padding-left: 40px;
          }
        }

        .level2-item {
          .text-box {
            padding-left: 64px;
          }
        }

        .level3-item {
          .text-box {
            padding-left: 88px;
          }
        }

        .algolia-search-block {
          order: -1;
          margin-top: 16px;
          margin-bottom: 16px;
          padding: 0 16px;

          .block-content {
            #algolia-searchbox {
              display: flex;
              align-items: center;
              position: relative;

              &:before {
                content: "";
                display: block;
                width: 1px;
                height: 29px;
                background-color: $static-border-gray;
                position: absolute;
                right: 46px;
                z-index: 1;
              }

              .algolia-autocomplete {
                input {
                  border: 1px solid $static-border-gray;
                  margin-top: 0;
                  margin-bottom: 0;
                  padding: 0 58px 0 12px;
                  height: 48px;
                  font-family: minion-pro, "ヒラギノ明朝 ProN W3", dnp-shuei-mincho-pr6n, "Hiragino Mincho ProN",
                    "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
                }
              }

              .magnifying-glass {
                bottom: auto;
                width: 22px;
                height: 21px;
                right: 12px;
                background: url("../images/search_icon.png");
                background-position: center;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }
}
