.owl-theme {
  // Styling Next and Prev buttons
  .owl-nav {
    margin-top: 6px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    [class*="owl-"] {
      color: $owl-nav-color;
      font-size: $owl-nav-font-size;
      margin: $owl-nav-margin;
      padding: $owl-nav-padding;
      background: $owl-nav-background;
      display: inline-block;
      cursor: pointer;
      border-radius: 3px;
      margin: 0;

      &:hover {
        background: transparent;
        color: $owl-nav-color-hover;
        text-decoration: none;
      }
    }
    .disabled {
      opacity: $owl-nav-disabled-opacity;
      cursor: default;
    }
  }

  // Styling dots
  .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
  }

  .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;

      span {
        width: $owl-dot-width;
        height: $owl-dot-height;
        margin: $owl-dot-margin;
        background: $owl-dot-background;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: $owl-dot-rounded;
      }

      &.active,
      &:hover {
        span {
          background: $owl-dot-background-active;
        }
      }
    }
  }
}
