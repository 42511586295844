.cms-home {
  .nav-sections {
    display: none;

    @include sp-screen {
      display: block;
    }
  }
}

.top-page-notice {
  margin-bottom: 23px;

  @include sp-screen {
    width: calc(100% - 32px);
    margin: 17px auto 12px;
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.81px;
    color: $text-red;
    margin: 0;
    text-align: right;

    @include sp-screen {
      text-align: left;
      line-height: 1.5;
    }
  }
}

.top-page-menu {
  display: flex;
  justify-content: space-between;

  .top-page-menu-wrapper {
    width: 172px;
    margin-right: 48px;

    @include sp-screen {
      display: none;
    }

    .top-page-menu__list {
      &__item {
        margin-bottom: 20px;

        a {
          color: $text-deep-gray;
          font-size: 14px;
          font-weight: 600;
          line-height: 0.93;
          letter-spacing: 2.15px;
        }
      }
    }

    .search {
      margin-top: 12px;
      width: 100%;
      height: 30px;
      position: relative;

      &-form {
        &__input {
          width: 100%;
          position: absolute;
          border: 0;
          padding: 0;
          border-bottom: 1px solid $text-gray;
          font-family: minion-pro, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝",
            serif;
        }

        &__icon {
          width: 15px;
          height: 15px;
          right: 0;
          bottom: 0;
          margin-bottom: 6px;
          display: inline-block;
          position: absolute;
          background: url("../images/search_icon.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .top-page-slider {
    width: 100%;
    max-width: 860px;
    height: auto;
    overflow: hidden;
    padding-bottom: 60px;

    @include md-screen {
      max-width: none;
    }

    @include sp-screen {
      padding-bottom: 72px;
    }

    &__list {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;

      .owl-stage-outer {
        width: 100%;
        height: 100%;

        .owl-stage {
          display: flex;
          height: 100%;

          .owl-item {
            width: auto;
          }
        }
      }

      .owl-nav {
        display: none;
      }

      .owl-dots {
        position: absolute;
        right: -2px;
        bottom: -28px;
        justify-content: flex-end;

        @include sp-screen {
          right: 0;
          bottom: -33px;
          justify-content: center;
        }

        .owl-dot {
          background: transparent;
          border: 0;
          padding: 0;
        }
      }

      &__item {
        max-width: 860px;
        padding-bottom: 64%;
        height: 0;
        display: block;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        @include md-screen {
          max-width: none;
        }

        &__link {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.top-page-recommend {
  @include sp-screen {
    width: calc(100% - 32px);
    margin: 0 auto;
  }

  &__title {
    color: $text-deep-gray;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 2.04px;
    margin-top: 0;
    margin-bottom: 16px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-top: -16px;
    margin-bottom: 140px;

    @include sp-screen {
      margin-left: -8px;
      margin-top: -8px;
      margin-bottom: 90px;
    }

    &__item {
      flex: 0 1 calc((100% / 4) - 16px);
      margin-left: 16px;
      margin-top: 16px;
      margin-bottom: 0;

      @include md-screen {
        flex: 0 1 calc((100% / 3) - 16px);
      }

      @include sp-screen {
        flex: 0 1 calc((100% / 2) - 8px);
        margin-left: 8px;
        margin-top: 8px;
      }

      .item-wrapper {
        .item-image img {
          display: block;
        }

        .item-content {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          margin-bottom: 7px;
          height: 36px;

          @include sp-screen {
            flex-direction: column;
            height: auto;
          }

          &__title {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.92px;
            width: 100%;
            max-width: 194px;
            max-height: 36px;
            overflow: hidden;

            @include md-screen {
              width: 14vw;
            }

            @include sp-screen {
              width: 100%;
              max-width: none;
            }
          }

          &__price {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include sp-screen {
              width: 100%;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              margin-top: 8px;
            }

            span {
              text-align: right;
            }

            .price {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.07;
              letter-spacing: 0.36px;
            }

            .tax {
              font-size: 10px;
              font-weight: 400;
              letter-spacing: 1.25px;
              color: $text-gray;

              @include sp-screen {
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.top-page-link {
  @include sp-screen {
    width: calc(100% - 32px);
    margin: 0 auto;
  }

  &__top {
    margin-bottom: 32px;

    @include sp-screen {
      margin-bottom: 0;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;

      @include sp-screen {
        flex-direction: column;
      }

      &__item {
        width: calc(50% - 20px);
        margin-bottom: 56px;

        @include sp-screen {
          width: 100%;
          margin-bottom: 22px;
        }

        .item-title {
          .title {
            margin-top: 0;
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: 600;
            line-height: 0.93;
            letter-spacing: 2.04px;
            color: $text-deep-gray;

            @include sp-screen {
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5;
              letter-spacing: 2.33px;
              margin-bottom: 9px;
            }
          }
        }

        .item-image {
          width: 100%;
          height: auto;
          position: relative;

          @include sp-screen {
            height: 56vw;
            max-height: none;
          }

          a {
            position: absolute;
            width: 100%;
            height: 100%;
          }

          .image {
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 0;
            padding-bottom: 61.5%;

            @include sp-screen {
              padding-bottom: 0;
              height: 100%;
            }
          }
        }
      }
    }
  }

  &__bottom {
    &__list {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;

      @include sp-screen {
        flex-direction: column;
      }

      &__item {
        flex: 1;

        @include sp-screen {
          width: 100%;
          margin-bottom: 22px;
        }

        .item-title {
          .title {
            margin-top: 0;
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: 600;
            line-height: 0.81;
            letter-spacing: 2.33px;

            @include sp-screen {
              font-weight: 500;
              line-height: 1.5;
              margin-bottom: 9px;
            }
          }
        }

        .item-image {
          width: 100%;
          height: auto;
          position: relative;

          @include sp-screen {
            height: 56vw;
            max-height: none;
          }

          a {
            position: absolute;
            width: 100%;
            height: 100%;
          }

          .image {
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 0;
            padding-bottom: 62%;

            @include sp-screen {
              padding-bottom: 0;
              height: 100%;
            }
          }
        }
      }

      &__item-middle {
        margin-left: 32.4px;
        margin-right: 32.4px;

        @include sp-screen {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
