@mixin arrow-icon(
  $arrow-color: $button__deep-gray,
  $tf: 135deg,
  $p: absolute,
  $w: 6px,
  $h: 6px,
  $t: null,
  $b: null,
  $l: null,
  $r: null
) {
  width: $w;
  height: $h;
  border: 0;
  border-top: solid 1px $arrow-color;
  border-right: solid 1px $arrow-color;
  transform: rotate($tf);
  position: $p;
  top: $t;
  bottom: $b;
  left: $l;
  right: $r;
}

@mixin font-family {
  font-family: minion-pro, "ヒラギノ明朝 ProN", 
  dnp-shuei-mincho-pr6n, "Hiragino Mincho ProN", 
  "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}