.form .fieldset .field {
  .control * {
    @include font-family;
  }
}

//
//  ログインページ
//  _____________________________________________

// ログインページ
.customer-account-login {
  .page-title-wrapper {
    .page-title {
      display: none;
    }
  }
  .columns {
    .column.main {
      @include sp-screen {
        padding: 0 16px;
      }
    }
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;

  .block-customer-login {
    width: 100%;
    padding-bottom: 37px;
    margin-bottom: 40px;
    border-bottom: 1px solid $button__light-gray;

    .block-title {
      margin: 0 0 32px 0;
      padding: 0;
      border-bottom: 0;

      strong {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 2.04px;
      }
    }

    .block-content {
      .form-login {
        fieldset {
          &:after {
            margin-top: 0;
          }
        }
        .login {
          .email {
            margin-bottom: 24px;
          }

          .password {
            margin-bottom: 40px;
          }

          .field {
            display: flex;
            flex-direction: column;

            .label {
              width: 100%;
              padding: 0;
              text-align: left;
              margin-bottom: 16px;

              span {
                font-size: 14px;
                font-weight: 600;
                line-height: 1.5;
                letter-spacing: 2.04px;
              }
            }

            .control {
              width: 100%;

              input {
                border: 1px solid $button__light-gray;
                height: 48px;
              }
            }
          }
          .actions-toolbar {
            .secondary {
              a.action {
                border: 0;
                margin-top: 0;
                height: auto;

                span {
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 2.04px;
                  color: $text-gray;
                  border-bottom: 1px solid $text-gray;
                }
              }
            }
          }
        }
      }
    }
  }

  .block-new-customer {
    width: 100%;
    padding-bottom: 37px;
    margin-bottom: 40px;
    border-bottom: 1px solid $button__light-gray;

    .block-title {
      border: 0;
      padding: 0;
      margin: 0 0 40px 0;
      text-align: center;

      strong {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.94px;
      }
    }

    .block-content {
      .actions-toolbar {
        .primary {
          a.action {
            display: flex;
          }
        }
      }
    }
  }

  .block-customer-forgotpassword {
    width: 100%;

    .block-title {
      border: 0;
      padding: 0;
      margin: 0 0 40px 0;
      text-align: center;

      strong {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.94px;
      }
    }

    .block-content {
      .actions-toolbar {
        .primary {
          a.action {
            display: flex;
          }
        }
      }
    }
  }
}

// ログアウトページ
.customer-account-logoutsuccess {
  .columns {
    .column.main {
      width: 100%;
      max-width: 560px;
      margin: 0 auto;

      @include sp-screen {
        padding: 0 16px;
      }
    }
  }
}

//
//  会員登録フォーム
//  _____________________________________________

.customer-account-create {
  .page-title-wrapper {
    .page-title {
      margin-top: 0;
    }
  }
}

.form.create.account,
.form-address-edit {
  width: 100%;
  max-width: 560px;
  min-width: auto;
  margin: 0 auto;

  @include sp-screen {
    width: calc(100% - 32px);
  }
}

.form-edit-account,
.form.create.account,
.form-address-edit {
  //会員登録フォーム共通指定
  .fieldset {
    .field {
      .label {
        padding: 0;
        text-align: left;
        margin-bottom: 16px;
        display: inline-flex;
        align-items: center;
        width: auto;
        float: none;

        span {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 2.04px;
        }
      }

      .control {
        width: 100%;
        float: none;

        input {
          width: 100%;
          border: 1px solid $button__light-gray;
          height: 48px;
        }

        .addon {
          display: flex;

          input:first-child {
            margin-right: 16px;
          }
        }
      }
    }

    .field.required {
      .label {
        &:after {
          content: "必須";
          font-size: 10px;
          font-weight: 400;
          line-height: 1;
          letter-spacing: 1.45px;
          color: $text-deep-gray;
          background-color: $products-slider__light-gray;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 31px;
          height: 17px;
        }
      }
    }

    .legend {
      display: block;
      width: 100%;

      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 2.04px;
      }
    }
  }

  .fieldset.address.h-adr {
    margin-top: 36px;
  }

  //会員登録フォーム個別指定
  .create {
    .field-name {
      display: flex;
      flex-direction: column;
    }

    .name {
      display: flex;
      flex-direction: column;
    }

    .password {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      .control {
        max-width: 338px;
      }
    }

    .confirmation {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      .control {
        max-width: 338px;
      }
    }
  }

  .address {
    .field {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      &:before {
        display: none;
      }

      .label {
        margin-bottom: 0;

        span {
          max-width: 81px;
        }
      }

      .label.street_2 {
        display: flex;
        flex-direction: column;
        align-items: initial;
      }

      .control {
        width: 76%;
        max-width: 422px;

        @include sp-screen {
          width: 59%;
        }
      }
    }

    .zip {
      display: block;

      .label {
        margin-bottom: 16px;
      }

      .addon {
        display: flex;
        align-items: center;

        @include sp-screen {
          align-items: initial;
          flex-direction: column;
        }

        div {
          display: flex;
          max-width: 338px;

          .control {
            width: 100%;
            max-width: 160px;
            margin-right: 16px;
          }

          .action {
            width: 100%;

            button {
              width: 100%;
              border: 0;
              border-radius: 0;
              background: $button__deep-gray;

              span {
                font-size: 13px;
                font-weight: 400;
                letter-spacing: 1.89px;
                color: $text-white;
              }
            }
          }
        }

        .search {
          padding-left: 24px;
          max-width: none;
          width: auto;

          @include sp-screen {
            padding-left: 0;
            padding-top: 14px;
          }

          a.action {
            text-align: right;

            span {
              font-size: 14px;
              font-weight: 400;
              color: $text-gray;
              border-bottom: 1px solid $text-gray;
            }
          }
        }
      }
    }

    .country {
      margin-bottom: 0;
    }

    .region {
      justify-content: flex-start;

      @include sp-screen {
        justify-content: space-between;
      }

      .label {
        width: 100%;
        max-width: 138px;

        @include sp-screen {
          max-width: none;
          width: auto;
        }
      }

      .control {
        max-width: 175px;
        position: relative;

        &:before {
          content: "";
          height: 40px;
          border-left: 1px solid $button__light-gray;
          position: absolute;
          top: 0;
          right: 40px;
        }

        &:after {
          content: "";
          @include arrow-icon($t: 15px, $r: 16px);
          display: block;
        }

        select {
          max-width: 175px;
          height: 40px;
          background: transparent;
          border: 1px solid $button__light-gray;
          padding: 0 52px 0 12px;
        }
      }
    }

    .field.choice.set {
      .label {
        width: auto;
        display: flex;
        align-items: center;
        margin-right: 0;

        span {
          max-width: none;
        }
      }
    }
  }

  .newsletter {
    .newsletter {
      display: flex;
      align-items: center;
      margin-bottom: 58px;
      position: relative;

      &:before {
        width: auto;
        padding: 0;
      }

      .label {
        display: flex;
        margin-bottom: 0;

        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 0.75;
        }
      }
    }
  }

  .terms-of-service {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    p {
      width: 100%;
      margin-bottom: 0;
    }

    textarea {
      height: 150px;
      margin-bottom: 32px;
      border: 1px solid $button__light-gray;
      resize: none;
    }

    .agreement {
      display: inline-block;
      margin-bottom: 40px;
      position: relative;

      @include sp-screen {
        margin-bottom: 32px;
      }

      .label {
        max-width: 174px;
        margin: 0 auto;

        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 0.75;
        }
      }

      div[for="agreement-check"] {
        width: 100%;
        display: block;
        text-align: center;

        @include sp-screen {
          max-width: 260px;
        }
      }
    }
  }

  div.mage-error[generated] {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 2.04px;
    color: $text-red;
  }
}

//
//  マイページ
//  _____________________________________________

// MYPAGE LIST 非表示
// 注文履歴、注文内容の詳細、会員情報変更、アドレス帳、住所変更、登録済みカード
.veritegmo-card-lists,
.veritegmo-card-edit,
.sales-order-history,
.sales-order-view,
.customer-account-edit,
.customer-address-index,
.customer-address-form {
  .sidebar-main {
    display: none;
  }
}

// SPレイアウト共通余白
.account.customer-address-index {
  .columns {
    .column.main {
      @include sp-screen {
        width: 100%;
      }

      .actions-toolbar {
        @include sp-screen {
          padding: 0 16px;
        }
      }
    }
  }
}
.account.customer-address-form {
  .columns {
    .column.main {
      @include sp-screen {
        width: 100%;
      }
    }
  }
}

// MYPAGE MAIN
.customer-account-index {
  .column.main {
    display: none;
  }
}

.account {
  .page.messages {
    margin-bottom: 0;
  }

  .columns {
    display: flex;
    flex-direction: column;
    max-width: 560px;
    margin: 0 auto;

    // MYPAGE MAIN LIST
    .sidebar-main {
      width: 100%;
      padding-right: 0;

      .account-nav {
        margin-bottom: 0;

        .account-nav-content {
          background: transparent;
          padding: 0;

          &:before {
            content: "MY PAGE";
            display: inline-block;
            width: 180px;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 2.04px;
            color: $text-black;

            @include sp-screen {
              padding-left: 16px;
            }
          }

          .items {
            .item {
              margin-top: 0;
              border-bottom: 1px solid $static-border-gray;

              &:last-child {
                border-bottom: none;
              }

              a {
                border: 0;
                padding: 16px;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 2.33px;
                color: $text-black;

                &:hover {
                  background: transparent;
                }
              }
            }

            .item.current {
              a {
                strong {
                  padding: 0;
                }
              }
              strong {
                border: 0;
                padding: 16px;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 2.33px;
              }
            }
          }
        }
      }
    }

    .column.main {
      width: 100%;
      order: -1;

      @include sp-screen {
        width: calc(100% - 32px);
        margin: 0 auto;
      }

      // 会員情報変更ページ
      .form-edit-account {
        // 会員情報変更ページ共通指定
        .fieldset {
          .field {
            margin-bottom: 32px;

            &:before {
              display: none;
            }

            .label {
              padding: 0;
              text-align: left;
              margin-bottom: 16px;
              display: flex;
              width: auto;

              span {
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 2.04px;
              }
            }

            .control {
              width: 100%;

              input {
                width: 100%;
                border: 1px solid $button__deep-gray;
                height: 48px;
              }

              .addon {
                display: flex;

                input:first-child {
                  margin-right: 16px;
                }
              }
            }
          }

          .legend {
            display: block;
            width: 100%;
            margin-bottom: 32px;

            span {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.5;
              letter-spacing: 2.04px;
            }
          }

          .choice {
            display: flex;
            margin-bottom: 16px;
          }
        }

        .fieldset.password {
          margin-top: 4px;
        }
      }

      // 注文履歴ページ
      .orders-history {
        .block-title {
          margin-bottom: 32px;

          strong {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 2.04px;
          }
        }

        .block-content {
          .orders-list {
            margin-bottom: 30px;

            .order {
              margin-bottom: 14px;
              padding-bottom: 16px;
              border-bottom: 1px solid $button__deep-gray;
              display: flex;
              justify-content: space-between;

              .order-info {
                .order-title {
                  margin-bottom: 16px;

                  span {
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 2.04px;
                  }
                }

                .order-status {
                  margin-bottom: 12px;

                  span {
                    font-size: 12px;
                    font-weight: 400;
                    letter-spacing: 1.74px;
                  }
                }

                .order-detail {
                  ul {
                    li {
                      margin-bottom: 12px;

                      &:last-child {
                        margin-bottom: 0;
                      }

                      .label {
                        color: $text-gray;
                      }

                      span {
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: 1.74px;
                      }
                    }
                  }
                }
              }
              .actions {
                position: relative;

                .order-detail-view {
                  position: absolute;
                  bottom: 0;
                  right: 0;

                  button {
                    width: 93px;
                    height: 28px;
                    padding: 0;
                    border-radius: 0;
                    border: 1px solid $button__light-gray;
                    background: transparent;

                    span {
                      font-size: 12px;
                      font-weight: 400;
                      letter-spacing: 1.74px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .order-products-toolbar {
        margin-bottom: 40px;

        .pager {
          .pages {
            position: static;
            float: none;

            .action {
              border: 0;
              margin: 0;
            }

            .action.previous {
              background: url("../images/arrow_left@2x.png");
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              width: 12px;
              height: 12px;
              margin: 0 18px;

              @include sp-screen {
                margin: 0 3.5vw;
              }

              &:before {
                display: none;
              }
            }

            .action.next {
              background: url("../images/arrow_right@2x.png");
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              width: 12px;
              height: 12px;
              margin: 0 18px;

              @include sp-screen {
                margin: 0 3.5vw;
              }

              &:before {
                display: none;
              }
            }

            .pages-items {
              display: flex;
              justify-content: center;
              align-items: center;

              .item {
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 1.87px;
                line-height: 1;
                color: $text-gray;
                margin: 0;
                display: flex;

                .page {
                  padding: 0;
                  margin: 0 18px;

                  @include sp-screen {
                    margin: 0 3.5vw;
                  }

                  span {
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.87px;
                    color: $text-gray;
                  }
                }

                .page.previous.jump {
                  display: none;
                }

                .page.next.jump {
                  display: none;
                }

                .page.first {
                  background: url("../images/arrow_first@2x.png");
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                  width: 12px;
                  height: 12px;

                  span {
                    display: none;
                  }
                }

                .page.last {
                  background: url("../images/arrow_last@2x.png");
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                  width: 12px;
                  height: 12px;

                  span {
                    display: none;
                  }
                }
              }

              .item.current {
                strong.page {
                  position: relative;
                  line-height: 1;

                  &:before {
                    content: "";
                    width: 100%;
                    bottom: -1px;
                    display: block;
                    position: absolute;
                    border-bottom: 1px solid $text-black;
                  }

                  span {
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.87px;
                    color: $text-black;
                  }
                }
              }
            }
          }
        }
      }

      .order-products-toolbar.toolbar.bottom {
        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }

      // 注文内容の詳細ページ
      .block-order-details-view {
        margin-bottom: 24px;

        @include sp-screen {
          padding-bottom: 24px;
        }

        .block-title {
          margin-bottom: 32px;

          strong {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 2.04px;
          }
        }

        .block-content {
          .row {
            ul {
              margin-bottom: 0;

              li {
                display: flex;
                padding-bottom: 12px;

                &:last-child {
                  margin-bottom: 12px;
                  border-bottom: 1px solid $static-border-gray;
                }

                .label {
                  color: $text-gray;
                  display: inline-block;
                  min-width: 104px;
                }

                span {
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: 1.74px;
                  display: inline-block;
                }
              }
            }
          }

          .order-shipping-address {
            ul {
              li {
                address {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 1.5;
                  letter-spacing: 2.04px;
                }
              }
            }
          }

          .order-shipping-method {
            ul {
              li {
                &:last-child {
                  padding-bottom: 0;
                  margin-bottom: 0;
                  border-bottom: 0;
                }
              }
            }
          }
        }
      }

      .order-details-items {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;

        .shipping-block-title {
          background-color: $cart-order-page__title-section;
          height: 30px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          padding: 0 16px;

          strong {
            font-size: 12px;
            font-weight: normal;
            line-height: 1.5;
            letter-spacing: 0.92px;
          }
        }
        .order-shipping-method {
          ul {
            margin-bottom: 0px;
            li {
              margin-bottom: 10px;
              span {
                font-weight: 600;
              }
            }
          }
        }

        .order {
          .block-order-items {
            margin-bottom: 0;

            .product-item-details {
              display: block;
              width: auto;
              padding: 0 16px;
              background: $cart-order-page__content-section;

              // 共通指定
              tr {
                display: block;

                th {
                  padding: 0;
                  border-top: 0;
                  border-bottom: 0;
                }

                td {
                  padding: 0;
                  border-top: 0;
                  border-bottom: 0;
                }
              }

              // 個別指定
              thead {
                display: block;

                tr {
                  display: block;
                  position: relative;

                  th {
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;

                    .item-name {
                      display: block;
                      width: 100%;
                      padding-top: 16px;

                      .name-private {
                        display: none;
                      }

                      .product-name {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 1.5;
                        letter-spacing: 0.92px;
                      }
                    }

                    .simple-info {
                      display: inline-block;
                      padding-right: 8px;
                      padding-top: 16px;
                      padding-bottom: 16px;
                      max-height: 48px;

                      &:after {
                        content: "";
                        width: 100%;
                        display: block;
                        position: absolute;
                        border-bottom: 1px dashed $static-border-gray;
                        bottom: 0;
                      }

                      .simple-thumbnail {
                        display: inline-block;

                        img {
                          width: 48px;
                          height: 48px;
                        }
                      }
                    }
                  }

                  td {
                    .simple-item {
                      position: absolute;
                      right: 0;
                      bottom: 16px;

                      .product-price {
                        .price {
                          font-size: 14px;
                          font-weight: 500;
                          letter-spacing: 1.07px;
                        }
                      }
                    }
                  }
                }
              }

              tbody {
                display: block;
                width: 100%;

                tr {
                  position: relative;
                  max-height: 48px;
                  padding-top: 16px;
                  padding-bottom: 16px;
                  border-bottom: 1px dotted $static-border-gray;

                  th {
                    display: flex;
                    max-height: 48px;

                    .simple-info {
                      display: inline-block;
                      padding-right: 8px;

                      .simple-thumbnail {
                        display: inline-block;

                        img {
                          width: 48px;
                          height: 48px;
                        }
                      }
                    }

                    .item-name {
                      font-size: 12px;
                      font-weight: 400;
                      letter-spacing: 0.31px;
                    }
                  }

                  td {
                    display: block;
                    position: relative;

                    .item-price {
                      text-align: right;
                      position: absolute;
                      right: 0;
                      bottom: 0;

                      .product-price {
                        .price {
                          font-size: 14px;
                          font-weight: 500;
                          letter-spacing: 1.07px;
                        }
                      }
                    }
                  }
                }

                .item-option {
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: 0.31px;
                }
              }

              tfoot {
                display: block;
                width: 100%;
                border-bottom: 1px solid $button__deep-gray;

                tr {
                  td {
                    display: block;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: 0;

                    span {
                      font-size: 12px;
                      font-weight: 400;
                      letter-spacing: 1.74px;
                    }
                  }
                }

                .assembly {
                  display: none;
                }

                .col.parent-item.qty {
                  .col.parent-item.qty {
                    text-align: right;
                    padding-top: 16px;
                    padding-bottom: 14px;
                  }
                }

                .col.parent-item.price {
                  .col.parent-item.price {
                    display: flex;
                    justify-content: flex-end;
                    padding-bottom: 16px;
                    border-bottom: 1px dotted $static-border-gray;

                    .subtotal-info {
                      .price-excluding-tax {
                        .cart-price {
                          .price {
                            .price {
                              font-size: 16px;
                              font-weight: 500;
                              line-height: 0.88;
                              letter-spacing: 1.22px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .shipping-info {
              background: $cart-order-page__content-section;


              tbody {
                .delivery-date {
                    border-bottom: 1px dotted $static-border-gray;
                }
                .delivery-time {
                  border-bottom: 1px solid $button__deep-gray;
                  margin-bottom: 12px;
                }

                tr {
                  margin: 0 16px;
                  display: flex;
                  justify-content: space-between;

                  th {
                    padding: 16px 0;

                    .label {
                      font-size: 12px;
                      letter-spacing: 2.04px;
                    }
                  }

                  td {
                    padding: 16px 0;

                    span {
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 1.5;
                      letter-spacing: 1.25px;
                    }
                  }
                }
              }
            }
          }

          .payment {
            background: $cart-order-page__content-section;
            tbody {
              display: flex;
              flex-direction: column;

              tr {
                margin: 0 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:last-child {
                  margin-top: 12px;
                  padding: 12px 0 16px;
                  border-top: 1px solid $static-border-gray;

                }

                th {
                  padding: 4px 0 2px;
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 2.04px;
                }

                td {
                  padding: 4px 0 2px;

                  .price {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 0.88;
                    letter-spacing: 1.22px;
                  }

                  .grand-total-price  {
                    span {
                      font-size: 20px;
                      font-weight: 600;
                      letter-spacing: 1.53px;
                    }
                  }
                }
              }
            }

            .amount {
              font-size: 12px;
            }

            .tax {
              font-size: 10px;
            }
          }
        }
      }

      // アドレス帳ページ
      .block-addresses-default {
        margin-bottom: 0;

        .block-title {
          margin-bottom: 16px;

          @include sp-screen {
            padding: 0 16px;
          }

          strong {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 2.04px;
          }
        }

        .block-content {
          display: flex;
          flex-direction: column;

          .box {
            width: 100%;
            clear: both;
            margin-bottom: 32px;

            .box-title {
              background-color: $cart-order-page__title-section;
              height: 30px;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              padding: 0 16px;

              span {
                font-size: 12px;
                font-weight: normal;
                line-height: 1.5;
                letter-spacing: 0.92px;
                margin: 0;
              }
            }

            .box-content {
              @include sp-screen {
                padding: 0 16px;
              }

              address {
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: 2.04px;
                padding-bottom: 14px;
              }
            }

            .box-actions {
              margin-top: 0;

              @include sp-screen {
                padding: 0 16px;
              }

              a.action.edit {
                border: 1px solid $text-black;
                padding: 6.5px 13px;
                display: inline-block;

                span {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 1;
                  letter-spacing: 2.04px;
                }
              }
            }
          }
        }
      }

      .block-addresses-list {
        margin-bottom: 0;

        @include sp-screen {
          padding: 0 16px;
        }

        .block-title {
          margin-bottom: 16px;

          strong {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 2.04px;
          }
        }

        .block-content {
          p.empty {
            margin-bottom: 32px;
          }

          .addresses {
            display: flex;
            flex-direction: column;

            .item {
              width: 100%;
              margin-bottom: 32px;
              margin-left: 0;

              address {
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: 2.04px;
                padding-bottom: 14px;
              }

              .actions {
                display: flex;
                margin: 0;

                a.action.edit {
                  border: 1px solid $text-black;
                  padding: 6.5px 13px;
                  display: inline-block;
                  margin-right: 16px;

                  span {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1;
                    letter-spacing: 2.04px;
                  }
                }

                a.action.delete {
                  border: 1px solid $text-black;
                  padding: 6.5px 13px;
                  display: inline-block;

                  span {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1;
                    letter-spacing: 2.04px;
                  }
                }
              }
            }
          }
        }
      }

      // 登録済みカードページ
      .block-cards {
        display: flex;
        flex-direction: column;

        &:before {
          content: '登録済みカード';
          color: $text-deep-gray;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.5;
          letter-spacing: 2.04px;
          margin-bottom: 40px;
        }

        .block-title {
          order: 1;

          .action.primary {
            margin: 0;
            margin-bottom: 24px;
          }

          .action.secondary {
            margin: 0;
            background-color: unset;
            border: 1px solid $button__light-gray;
            border-radius: 2px;

            span {
              font-size: 13px;
              font-weight: 400;
              letter-spacing: 2.04px;
              color: $text-deep-gray;
            }
          }
        }

        .block-content {
          .cards-registered {
            .table-card-items {
              thead {
                display: none;
              }

              tbody {
                tr {
                  display: flex;
                  flex-direction: column;

                  td {
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                    margin-bottom: 40px;

                    &:before {
                      font-size: 14px;
                      letter-spacing: 2.04px;
                      color: $text-deep-gray;
                      margin-bottom: 14px;
                    }
                  }

                  .id:before {
                    content: 'クレジットカード番号';
                  }

                  .date:before {
                    content: '有効期限日';
                  }

                  .actions {
                    margin-bottom: 25px;
                    &:before {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          .message.info {
            padding: 0;
            background: transparent;
            color: $text-deep-gray;
            font-size: 14px;
            letter-spacing: 2.04px;
            margin-bottom: 50px;

            span:before {
              display: none;
            }
          }
        }

        .action {
          width: 100%;
          height: 48px;
          margin: 0;
          border: none;
          border-radius: 2px;
          background-color: $text-deep-gray;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 13px;
            font-weight: normal;
            letter-spacing: 1.89px;
            color: $text-white;
          }
        }
      }

      .form-edit-card {
        .fieldset {
          &:before {
            content: 'カード登録';
            color: $text-deep-gray;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 2.04px;
            margin-bottom: 40px;
          }

          .field {
            display: flex;
            flex-direction: column;

            &:nth-child(3) {
              .control {
                .fields {
                  .field {
                    width: auto !important;
                    position: relative;

                    .control {
                      width: 88px;
                      position: relative;

                      &:before {
                        content: "";
                        height: 40px;
                        border-left: 1px solid $button__light-gray;
                        position: absolute;
                        top: 0;
                        left: 40px;
                      }

                      &:after {
                        content: "";
                        @include arrow-icon($t: 15px, $l: 17px);
                        display: block;
                      }

                      select {
                        height: 40px;
                        padding-left: 50px;
                        overflow: hidden;
                        margin: 0;
                      }

                      .mage-error[generated] {
                        white-space: nowrap;
                      }
                    }
                  }

                  .month {
                    margin-right: 50px;

                    &:after {
                      content: '月';
                      font-size: 14px;
                      color: $text-deep-gray;
                      position: absolute;
                      top: 10px;
                      right: -24px;
                    }
                  }

                  .year {
                    &:after {
                      content: '日';
                      font-size: 14px;
                      color: $text-deep-gray;
                      position: absolute;
                      top: 10px;
                      right: -24px;
                    }
                  }
                }
              }
            }

            &:nth-child(4) {
              .control {
                input {
                  width: 88px;
                }
              }
            }

            .label {
              padding: 0;
              text-align: left;
              margin-bottom: 16px;
              display: inline-flex;
              align-items: center;
              width: auto;
              float: none;

              span {
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 2.04px;
              }
            }

            .control {
              width: 100%;
              float: none;

              input,
              select {
                width: 100%;
                border: 1px solid $button__light-gray;
                height: 48px;
              }
            }
          }

          .field.type {
            margin-top: 40px;

            .control {
              position: relative;

              &:before {
                content: "";
                height: 48px;
                border-left: 1px solid $button__light-gray;
                position: absolute;
                top: 0;
                right: 48px;
              }

              &:after {
                content: "";
                @include arrow-icon($t: 20px, $r: 20px);
                display: block;
              }
            }
          }

          .field.required {
            .label {
              &:after {
                content: "必須";
                font-size: 10px;
                font-weight: 400;
                line-height: 1;
                letter-spacing: 1.45px;
                color: $text-deep-gray;
                background-color: $products-slider__light-gray;
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 31px;
                height: 17px;
              }
            }
          }

          .legend {
            display: block;
            width: 100%;

            span {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.5;
              letter-spacing: 2.04px;
            }
          }
        }
      }
    }
  }
}

//
//  パスワードの再設定画面
//  _____________________________________________

.customer-account-forgotpassword {
  .form.password.forget {
    width: 100%;
    min-width: auto;
    max-width: 560px;
    margin: 0 auto;

    @include sp-screen {
      width: calc(100% - 32px);
    }

    .fieldset {
      .note {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 2.04px;
        color: $text-deep-gray;
        margin-bottom: 32px;
      }

      // email, captcha 箇所共通
      .email,
      .captcha {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        .label {
          width: auto;
          text-align: left;
          padding: 0;
          margin-bottom: 16px;

          span {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 2.04px;
            color: $text-deep-gray;
          }
        }

        .control {
          width: 100%;

          input {
            height: 48px;
            border: 1px solid $button__light-gray;
            padding: 0 16px;
            margin-bottom: 24px;
          }

          .nested {
            margin-bottom: 40px;

            .captcha {
              display: block;

              .captcha-image {
                margin-top: 0;
                display: flex;
                align-items: center;

                @include sp-screen {
                  flex-direction: column;
                }

                img {
                  margin: 0;
                  width: 252px;
                  height: 68px;
                  margin-right: 16px;

                  @include sp-screen {
                    margin-right: 0;
                    margin-bottom: 16px;
                  }
                }

                button {
                  max-height: 44px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.opc-wrapper {
  @include pc-screen {
    width: auto;
  }
}
.nav-sections {
  @include sp-screen {
    display: none;
  }
}

//
//  パスワード再設定画面（メールのURLからきた場合）
//  _____________________________________________

.customer-account-createpassword {
  .form.password.reset {
    width: 100%;
    min-width: auto;
    max-width: 560px;
    margin: 0 auto;

    @include sp-screen {
      width: calc(100% - 32px);
    }

    .fieldset {
      .field {
        margin: 0;
        display: flex;
        flex-direction: column;

        .label {
          width: auto;
          text-align: left;
          padding: 0;
          margin-bottom: 16px;

          span {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 2.04px;
            color: $text-deep-gray;
          }
        }

        .control {
          width: 100%;

          .input-text {
            height: 48px;
            border: 1px solid $button__light-gray;
            padding: 0 16px;
            margin-bottom: 24px;
          }
        }
      }

      .field.confirmation {
        padding-bottom: 8px;
      }
    }
  }
}
