$button__light-gray: #979797;
$button__deep-gray: #333333;

$cart-order-page__content-section: #eeeeee;
$cart-order-page__title-section: #e0e0e0;

$product-page__title-section: #e6e6e6;

$products-slider__light-gray: #d8d8d8;
$products-slider__deep-gray: #666666;

$text-black: #281e1b;
$text-gray: #7b7b7b;
$text-white: #ffffff;
$text-red: #e73656;
$text-blue: #5291c9;
$text-deep-gray: #333333;

$static-border-gray: #cecece;

$footer-background-gray: #767570;
$footer-text-white: #fefefe;

$background-light-gray: #efeded;
