//
//  商品のメインスライダー箇所
//  _____________________________________________

// レイアウト
.gallery-placeholder {
  width: 100%;
  max-width: calc(496px * 0.85);
  padding-bottom: 30px;

  @include md-screen {
    margin: 0 auto;
  }

  @include sp-screen {
    width: auto;
    padding-bottom: 7px;
  }

  .fotorama-item {
    .fotorama__wrap {
      .note__button {
        position: absolute;
        right: 32px;
        bottom: 33px;
        z-index: 10;
        border-bottom: 1px solid $text-black;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1.84px;
        cursor: pointer;

        @include sp-screen {
          right: 16px;
          bottom: 26px;
        }
      }

      .note__content {
        position: absolute;
        z-index: 1;

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-color: #1c3232;
          opacity: 0.5;
        }

        &--text {
          position: absolute;
          top: 0;
          padding: 40px;
        }

        &--text,
        p {
          color: $text-white;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.8;
          letter-spacing: 0.92px;
          text-align: justify;
        }
      }
    }
  }
}

.fotorama__stage__frame {
  .fotorama__img {
    width: 100%;
  }
}

//スライダー下箇所
.fotorama__nav-wrap--horizontal {
  position: relative;
  margin-top: 30px;

  @include sp-screen {
    margin-top: 15px;
  }
}

//矢印
.fotorama__arr {
  top: auto;
  display: block !important;
  width: 30px;
  height: 30px;
  transform: translate3d(0, 0, 0) !important;
  opacity: 1 !important;

  &:hover {
    background-color: transparent;
  }

  .fotorama__arr__arr {
    background-image: none;
  }
}

.fotorama__arr--prev {
  display: inline-block;
  position: absolute;
  left: 0;

  &:before {
    content: "";
    width: 9px;
    height: 9px;
    border: 0px;
    border-top: solid 2px $products-slider__deep-gray;
    border-right: solid 2px $products-slider__deep-gray;
    transform: rotate(-135deg);
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -4px;

    @include sp-screen {
      left: 27px;
    }
  }
}

.fotorama__arr--next {
  display: inline-block;
  position: absolute;
  right: 0;

  &:before {
    content: "";
    width: 9px;
    height: 9px;
    border: 0;
    border-top: solid 2px $products-slider__deep-gray;
    border-right: solid 2px $products-slider__deep-gray;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -4px;

    @include sp-screen {
      right: 27px;
    }
  }
}

//ドット
.fotorama__nav__frame {
  .fotorama__nav--dots & {
    width: 16px;
  }
}
.fotorama__dot {
  width: 8px;
  height: 8px;
  border: none;
  background-color: $products-slider__light-gray;
}
.fotorama__active {
  .fotorama__dot {
    background-color: $products-slider__deep-gray;
  }
}

//
//  その他サイズ・関連商品のスライダー
//  _____________________________________________

// レイアウト
.block.upsell,
.block.related {
  @include sp-screen {
    width: calc(100% - 32px);
    margin: 0 auto;
  }
}

.column.main .product-items {
  margin-left: 0;
  position: relative;
}

.owl-stage-outer {
  .owl-stage {
    .owl-item {
      .product-item {
        width: 100%;
        padding: 0;
        margin: 0;

        .product-item-info {
          width: auto;

          a {
            display: block;
          }
        }
      }
    }
  }
}

.products {
  margin: 10px 0 44px;
}

// SPレイアウト
.products-upsell {
  .product-items {
    @include sp-screen {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .product-item {
      @include sp-screen {
        width: calc(50% - 4px);
      }
    }
  }
}

.products-related {
  .product-items {
    @include sp-screen {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &:after {
        content: '';
        width: calc(33% - 4px);
        margin-bottom: 4px;
      }
    }

    .product-item {
      @include sp-screen {
        width: calc(33% - 4px);
        margin-bottom: 4px;
      }
    }
  }
}

.product-item-info {
  @include sp-screen {
    width: auto;
  }
  .product-item-photo {
    .image {
      @include sp-screen {
        max-width: none;
        width: 100%;
      }
    }
  }
}

.column.main .product-item {
  @include sp-screen {
    padding-left: 0;
  }
}

// スライダータイトル
.block .title {
  margin-bottom: 14px;
  @include sp-screen {
    margin-bottom: 6px;
  }
}

#block-upsell-heading {
  font-size:14px;
  font-weight: 400;
  letter-spacing: 1.2px;
}

#block-related-heading {
  font-size:14px;
  font-weight: 400;
  letter-spacing: 1.2px;
}

.product-item-info {
  .product-item-details {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 15px;
    margin-bottom: 12px;

    @include sp-screen {
      flex-direction: column;
    }
    .product-item-name {
      font-size: 12px;
      margin: 0;
    }
    .price-box {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-top: 2px;

      @include sp-screen {
        flex-direction: initial;
        justify-content: flex-end;
        align-items: center;
      }
      .price-label {
        display: none;
      }
      .price {
        display: flex;

        @include sp-screen {
          margin-right: 16px;
        }

        .price-symbol, .price-amount {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.36px;
          line-height: 1.07;
        }
      }
      .price-tax {
        font-size: 12px;
        text-align: right;
        color: $text-gray;
        letter-spacing: 1.25px;
      }
      .price-to {
        display: none;
      }
    }
  }
}

// 関連商品スライダー矢印
.owl-nav {
  display: flex;
  justify-content: space-between;

  button {
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 1;
    box-shadow: none;

    span {
      display: none;
    }
  }

  .owl-prev {
    display: inline-block;
    position: absolute;
    left: 0;

    &:after {
      content: "";
      width: 9px;
      height: 9px;
      border: 0px;
      border-top: solid 2px $products-slider__deep-gray;
      border-right: solid 2px $products-slider__deep-gray;
      transform: rotate(-135deg);
      position: absolute;
      top: 50%;
      left: 10px;
      margin-top: -4px;

      @include sp-screen {
        left: 27px;
      }
    }
  }

  .owl-next {
    display: inline-block;
    position: absolute;
    right: 0;

    &:after {
      content: "";
      width: 9px;
      height: 9px;
      border: 0;
      border-top: solid 2px $products-slider__deep-gray;
      border-right: solid 2px $products-slider__deep-gray;
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -4px;

      @include sp-screen {
        right: 27px;
      }
    }
  }
}

// スライダードット
.owl-dots {
  position: relative;
  bottom: -7px;
  width: 100%;
  display: flex;
  justify-content: center;

  .owl-dot {
    box-shadow: none;
  }
}
