// Look Bookページ
.look-book-page {
  width: 100%;
  margin: 0 auto;

  @include sp-screen {
    width: calc(100% - 32px);
    max-width: none;
  }

  .book {
    &__title {
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 1.2px;
      margin-bottom: 14px;
      color: $text-deep-gray;

      @include sp-screen {
        line-height: 1.5;
        margin-bottom: 6px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 0;

      &__item {
        width: calc(50% - 20px);
        margin-bottom: 0;

        &:nth-last-child(n + 3) {
          margin-bottom: 40px;

          @include sp-screen {
            margin-bottom: 32px;
          }
        }

        &:last-child {
          @include sp-screen {
            margin-bottom: 0;
          }
        }

        @include sp-screen {
          flex-direction: column;
          width: 100%;
          margin-bottom: 32px;
        }

        .article {
          &__content {
            max-height: 326px;

            @include sp-screen {
              max-height: none;
            }

            iframe {
              height: 326px;

              @include sp-screen {
                height: 57.5vw;
              }
            }
          }

          &__title {
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
            letter-spacing: 2.15px;
            color: $text-deep-gray;

            span {
              display: inline-block;
              padding-top: 16px;
            }
          }
        }
      }
    }
  }
}
