a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

ul {
  padding-left: 0;
  list-style: none;
}

button:active {
  box-shadow: none;
}

button[disabled] {
  opacity: 1;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

.page-main {
  flex-grow: 0;
}

.page-footer {
  margin-top: 0;
}

.column.main {
  min-height: auto;
}

.columns .column.main {
  padding-bottom: 0;
}

#maincontent {
  width: calc(100% - 200px);
  max-width: 1080px;
  padding: 40px 0 0;

  @include sp-screen {
    width: auto;
    padding: 6px 0 0;
  }
}

// パンくず
.breadcrumbs {
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 0;

  @include sp-screen {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 10px;
  }

  .items {
    font-size: 12px;

    .item {
      letter-spacing: 0.5px;
    }
  }
}

.page-title-wrapper {
  width: 100%;
  max-width: 560px;
  margin: 0 auto;

  @include sp-screen {
    width: calc(100% - 32px);
  }

  .page-title {
    margin-top: 40px;
    margin-bottom: 32px;
    font-size: 0;

    .base {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 2.04px;
    }
  }
}

// ボタン
.actions-toolbar {
  display: flex;
  flex-direction: column;
  margin: 0 !important;

  .primary {
    margin-bottom: 24px;

    .action {
      width: 100%;
      height: 48px;
      margin: 0;
      border: none;
      border-radius: 2px;
      background-color: $button__deep-gray;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 1.89px;
        color: $text-white;
      }
    }
  }

  .secondary {
    a.action {
      margin: 0;
      width: 100%;
      height: 48px;
      border: 1px solid $button__light-gray;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 1.89px;
      }
    }

    button {
      width: 100%;
      height: 48px;
      border: 1px solid $button__light-gray;
      border-radius: 2px;
      background: transparent;

      span {
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 1.89px;
      }
    }
  }
}

// IMGを中央にする
.image-container {
  position: relative;
  overflow: hidden;

  &__centered {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(50%, 50%);

    img {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
    }

    // 縦が長い画像用
    .portrait {
      max-width: none;
      width: 100%;
      height: auto;
    }

    // 横が長い画像用
    .landscape {
      max-width: none;
      width: auto;
      height: 100%;
    }
  }
}

// Checkboxデザイン
input[type="checkbox"] + label {
  position: relative;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    border: 1px solid $text-black;
    pointer-events: auto;
    cursor: pointer;
  }
}

input[type="checkbox"]:checked + label {
  &:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 6px;
    width: 7px;
    height: 11px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }
}

input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}

.message.success {
  font-size: 14px;
}

.control {
  .mage-error[generated] {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2.04px;
    color: $text-red;
  }
}

// 商品一覧ページの場合、タイトル非表示
.page-products {
  .page-title-wrapper {
    display: none;
  }
}

// 会員情報変更ページの場合、タイトル非表示
.account {
  .page-title-wrapper {
    display: none;
  }
}

.fieldset > .field {
  &:after {
    display: none;
  }
}

.sections.nav-sections {
  @include sp-screen {
    display: block;
  }
}

// アラートメッセージ
.page.messages {
  .message {
    background-color: $footer-background-gray;
    padding-left: 20px;

    div[data-bind="html: message.text"], div[data-ui-id="checkout-cart-validationmessages-message-error"] {
      color: $text-white;

      // アイコン非表示
      &:before {
        display: none;
      }
    }
  }
}
