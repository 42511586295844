//
//  Guide, Help, Privacy, Tokushoho Page
//  _____________________________________________

#page-wrap {
  @include sp-screen {
    width: calc(100% - 32px);
    margin: 35px auto;
  }

  .page-title {
    font-size: 18px;
    font-weight: 600;
    margin-left: 236px;
    margin-bottom: 34px;
    padding-left: 90px;
    letter-spacing: 2.62px;

    @include sp-screen {
      margin-left: 0;
      padding-left: 0;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 2.04px;
      margin-bottom: 24px;
    }
  }

  .page-content {
    display: flex;

    @include sp-screen {
      flex-direction: column;
    }

    &__left {
      &__list {
        list-style: none;
        padding: 0;
        margin: 0 90px 0 0;
        width: 236px;

        @include sp-screen {
          width: 100%;
          margin: 0 0 37px 0;
        }

        &__item {
          padding: 11px 0 10px 0;
          margin-bottom: 0;
          border-bottom: 1px dashed $static-border-gray;

          @include sp-screen {
            padding: 10px 0 8px 0;
          }

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            border-bottom: 1px solid $static-border-gray;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 2.04px;
            margin-bottom: 0;

            @include sp-screen {
              font-size: 12px;
              letter-spacing: 1.74px;
            }

            a {
              color: $text-black;
            }
          }
        }
      }
    }

    &__right {
      p {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.74px;
      }

      &__content {
        margin-bottom: 29px;
        padding-top: 3px;
        line-height: 2;
      }

      &__list {
        list-style: none;
        padding: 0;
        margin: 0;

        &__item {
          margin-bottom: 0;

          h2 {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 2.04px;
            border-top: 1px solid $text-black;
            border-bottom: 1px solid $text-black;
            margin: 0 0 15px 0;
            padding: 13px 0 7px;
          }

          p {
            margin-bottom: 32px;
            line-height: 1.5em;

            a {
              border-bottom: 1px solid $text-gray;
              color: $text-gray;
            }
          }

          .list {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            font-weight: 400;
            line-height: 2.9;
            letter-spacing: 1.74px;
            margin-bottom: 22px;

            &__item {
              padding-left: 17px;
              position: relative;

              &:before {
                content: "";
                display: block;
                width: 4px;
                height: 4px;
                background-color: $text-black;
                border-radius: 50%;
                top: 15px;
                left: 7px;
                position: absolute;
              }
            }
          }

          &__inner {
            &:last-child {
              .content {
                p {
                  margin-bottom: 0;
                }
              }
            }

            .title {
              background-color: $products-slider__light-gray;
              padding: 12px 13px;
              margin-bottom: 24px;

              h3 {
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 1.74px;
                margin: 0;
              }
            }

            .content {
              p {
                margin-bottom: 32px;
              }
            }
          }
        }
      }
    }
  }
}
