.content-none {
  width: 100%;
  max-width: 560px;
  margin: 0 auto;

  @include sp-screen {
    width: calc(100% - 32px);
  }

  h1 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 2.04px;
    margin-bottom: 21px;
  }

  h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 2.04px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 3;
    letter-spacing: 1.74px;
    margin-bottom: 0;
  }
}
