#about-page-wrap {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

.about-page {
  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 2.04px;
    margin-bottom: 16px;

    @include sp-screen {
      width: calc(100% - 64px);
      margin: 0 auto 15px;
    }
  }

  &__thumbnail-wrapper {
    width: 100%;
    margin-bottom: 35px;

    @include sp-screen {
      margin-bottom: 32px;
    }
  }

  &__content {
    width: 100%;
    max-width: 594px;
    margin: 0 auto;

    @include sp-screen {
      width: calc(100% - 64px);
      max-width: none;
    }

    &__image-wrapper {
      width: 100%;
    }

    h2 {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 2.04px;
      margin-top: 0;
      margin-bottom: 11px;

      @include sp-screen {
        margin-bottom: 15px;
      }
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.8;
      letter-spacing: 1.74px;
      margin-bottom: 58px;

      @include sp-screen {
        margin-bottom: 53px;
      }
    }
  }
}

.thumbnail {
  padding-top: 62.2%;
}

.content-image {
  padding-top: 62.2%;

  @include sp-screen {
    padding-top: 61.1%;
  }
}
