// 会社概要ページ
.corporate-profile-page {
  width: 100%;
  max-width: 594px;
  margin: 0 auto;

  @include sp-screen {
    width: calc(100% - 32px);
    max-width: none;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 2.04px;
    margin-bottom: 15px;
  }

  &__table {
    tbody {
      tr {

        // 共通
        td {
          font-size: 12px;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 1.74px;
          text-align: justify;
        }

        // 個別
        .type {
          width: 70px;
          padding: 0 24px 15px 0;
        }

        .detail {
          padding: 0 0 15px 0 ;
        }
      }
    }
  }
}