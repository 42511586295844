//
//  商品一覧画面
//  _____________________________________________

$algolia-pagination-arrow-width: 12px;
$algolia-pagination-arrow-height: 12px;

.algolia-instant-results-wrapper {
  .algolia-instant-selector-results {
    // 左右要素のレイアウト
    #algolia_instant_selector .col-md-3,
    #algolia_instant_selector .col-md-9,
    #algolia_instant_selector .col-md-12 {
      padding-left: 0;
      padding-right: 0;
      clear: both;
    }

    // 右側の商品一覧要素のレイアウト
    #algolia_instant_selector .col-md-4 {
      padding-left: 8px;
      padding-right: 8px;

      @include sp-screen {
        padding-left: 0;
        padding-right: 0;
      }
    }

    #algolia_instant_selector {
      margin: 0;

      .row {
        margin-left: 0;
        margin-right: 0;
        display: flex;

        @include md-screen {
          display: block;
        }
      }

      // 左側のカテゴリーレイアウト
      #algolia-left-container {
        max-width: 250px;
        margin-right: 25px;

        @include md-screen {
          width: 100%;
          max-width: 100%;
          margin-right: 0;
        }

        @include sp-screen {
          width: calc(100% - 32px);
          margin: 0 auto;
        }

        .visible-sm {
          @include md-screen {
            display: none !important;
          }
        }

        #instant-search-facets-container {
          @include md-screen {
            display: block !important;
          }

          // カテゴリー上に選択した情報を表示する箇所
          #current-refinements {
            .facet {
              .ais-header {
                display: none;
              }

              .ais-body {
                padding-left: 0;

                .ais-current-refined-values--clear-all {
                  display: none;
                }

                .ais-current-refined-values--list {
                  display: flex;
                  flex-wrap: wrap;
                  margin-bottom: 32px;

                  @include sp-screen {
                    margin-bottom: 16px;
                  }

                  .ais-current-refined-values--item {
                    margin-top: 0;

                    .ais-current-refined-values--link {
                      border: 0;
                      padding: 0;
                      background-color: transparent;
                      display: inline-block;
                      margin-right: 14px;

                      .cross-wrapper {
                        display: none;
                      }

                      .current-refinement-wrapper {
                        padding-left: 0;
                        width: auto;

                        .current-refinement-label {
                          display: none;
                        }

                        .current-refinement-name {
                          font-size: 14px;
                          font-weight: 600;
                          line-height: 1.5;
                          letter-spacing: 2.04px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          // カテゴリーレイアウト(共通)
          .facet {
            border: 0;
            padding-bottom: 0;
            margin-bottom: 0;
            background-color: transparent;

            .ais-header {
              margin-bottom: 11px;
              cursor: pointer;
              position: relative;

              @include sp-screen {
                margin-bottom: 16px;
              }

              &:after {
                content: "";
                @include arrow-icon($t: 8px, $l: 110px);
              }
            }

            .ais-body {
              padding-left: 18px;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 1.85px;
            }

            .name {
              padding: 0;
              background-color: transparent;
              color: $text-black;
              font-size: 16px;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: 2.04px;

              @include sp-screen {
                font-size: 14px;
              }
            }

            .searchbox {
              display: none;
            }
          }

          // 押された時矢印回転
          .facet.ais-root__collapsed {
            .ais-header {
              &:after {
                transform: rotate(45deg);
              }
            }
          }

          // カテゴリーレイアウト(カテゴリー)
          .hierarchical {
            .ais-hierarchical-menu--body {
              margin-bottom: 12px;

              // 選択した時に表示される青いボックス非表示
              .ais-hierarchical-menu--item__active {
                .ais-hierearchical-link-wrapper {
                  .ais-hierarchical-menu--link {
                    box-shadow: none;
                  }
                }
              }

              .ais-hierearchical-link-wrapper {
                padding: 0 0 11px;

                @include sp-screen {
                  padding: 0 0 16px;
                }

                .ais-hierarchical-menu--link {
                  padding: 0;
                  position: relative;

                  &:before {
                    content: "";
                    width: 6px;
                    height: 1px;
                    display: block;
                    background: $text-black;
                    position: absolute;
                    left: -11px;
                    top: 50%;
                  }

                  .ais-hierarchical-menu--item--prefix-symbol {
                    display: none;
                  }
                }
              }

              .ais-hierarchical-menu--list__lvl1 {
                padding-left: 18px;
              }

              .ais-hierarchical-menu--list__lvl2 {
                padding-left: 18px;
              }
            }
          }

          // カテゴリーレイアウト(樹種・シリーズ)
          .conjunctive {
            .ais-refinement-list--body {
              margin-bottom: 12px;

              // 選択した時に表示される青いボックス非表示
              .ais-refinement-list--item__active {
                .ais-refinement-list--label {
                  box-shadow: none;
                }
              }

              .ais-refinement-list--item {
                padding: 0 0 11px;

                @include sp-screen {
                  padding: 0 0 16px;
                }

                .ais-refinement-list--label {
                  padding: 0;
                  position: relative;

                  &:before {
                    content: "";
                    width: 6px;
                    height: 1px;
                    display: block;
                    background: $text-black;
                    position: absolute;
                    left: -11px;
                    top: 50%;
                  }

                  .ais-refinement-list--item--prefix-symbol {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      #algolia-right-container {
        @include md-screen {
          width: 100%;
        }

        .row {
          margin-left: -8px;
          margin-right: -8px;
          display: block;

          @include sp-screen {
            margin-left: 0;
            margin-right: 0;
          }

          .col-md-12 {
            padding: 0 8px;

            @include sp-screen {
              padding: 0;
            }

            .introduction {
              .series {
                .slider {
                  width: 100%;
                  max-width: 805px;
                  overflow: hidden;

                  @include md-screen {
                    max-width: none;
                  }

                  .owl-carousel {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    padding-bottom: 55px;

                    .owl-stage-outer {
                      width: 100%;
                      height: 100%;

                      .owl-stage {
                        display: flex;
                        height: 100%;
                      }
                    }

                    .owl-nav {
                      display: none;
                    }

                    .owl-dots {
                      position: absolute;
                      right: -2px;
                      bottom: 20px;
                      justify-content: flex-end;

                      @include sp-screen {
                        right: 0;
                        bottom: 21px;
                        justify-content: center;
                      }

                      .owl-dot {
                        background: transparent;
                        border: 0;
                        padding: 0;
                        margin: 0;
                      }
                    }

                    .item {
                      max-width: 805px;
                      height: 100%;
                      display: block;
                      background-position: center;
                      background-size: cover;

                      @include md-screen {
                        max-width: none;
                      }

                      img {
                        height: 100%;
                      }
                    }
                  }
                }

                .concept {
                  padding-bottom: 27px;

                  @include sp-screen {
                    width: calc(100% - 32px);
                    margin: 0 auto;
                    padding-bottom: 68px;
                  }

                  .title {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.29;
                    letter-spacing: 2.04px;
                    margin-bottom: 10px;
                  }

                  .content {
                    font-size: 12px;
                    line-height: 1.8;
                    letter-spacing: 1.74px;
                    text-align: justify;
                  }
                }
              }
            }
          }

          // 商品情報を表示する箇所
          .hits {
            @include sp-screen {
              width: calc(100% - 32px);
              margin: 0 auto;
            }

            .infos {
              margin: 0 8px;
              padding: 0;
              background: transparent;

              @include sp-screen {
                margin: 0;
              }

              .pull-left {
                strong {
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: 2.04px;
                  position: relative;
                  top: 5px;
                }
              }

              .pull-right {
                .ais-body {
                  position: relative;

                  &:before {
                    content: "";
                    @include arrow-icon($t: 16px, $r: 2px);
                    z-index: 1;
                  }

                  .ais-sort-by-selector {
                    border: 0;
                    padding: 0;
                    font-size: 12px;
                    letter-spacing: 1.42px;
                    direction: rtl;
                    padding-right: 20px;
                    height: 16px;
                    position: relative;
                    top: 5px;
                  }
                }
              }
            }

            #instant-search-results-container {
              .ais-hits {
                @include sp-screen {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                }

                &--item {
                  @include sp-screen {
                    width: calc(100% / 2 - 4px);
                  }

                  .result-wrapper {
                    .result {
                      padding: 0;
                      border: 0;
                      margin: 8px 0;

                      &-content {
                        .result-thumbnail {
                          img {
                            width: 100%;
                            height: auto;
                          }
                        }
                        .result-sub-content {
                          display: flex;
                          justify-content: space-between;
                          margin-top: 10px;
                          margin-bottom: 15px;
                          height: 36px;

                          @include sp-screen {
                            flex-direction: column;
                            margin-top: 8px;
                            margin-bottom: 14px;
                            height: auto;
                          }

                          .result-title {
                            margin: 0;
                            text-align: left;
                            font-size: 12px;
                            line-height: 1.5;
                            letter-spacing: 0.92px;
                            white-space: normal;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;

                            @include sp-screen {
                              margin-bottom: 5px;
                            }

                            // 検索結果キーワードに背景色が付いてることをなしにする。
                            em {
                              background-color: transparent;
                            }
                          }

                          .ratings {
                            .result-sub-content {
                              display: flex;
                              align-items: center;
                              margin-top: 0;
                              margin-bottom: 0;
                              padding-bottom: 4px;

                              @include sp-screen {
                                align-items: flex-end;
                                padding-bottom: 0;
                              }

                              .price {
                                &-wrapper {
                                  div {
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: 16px;

                                    @include sp-screen {
                                      flex-direction: row;
                                      justify-content: flex-end;
                                      align-items: center;
                                      margin-left: 0;
                                    }

                                    .after_special {
                                      font-size: 14px;
                                      font-weight: 600;
                                      line-height: 1.07;
                                      letter-spacing: 0.36px;
                                    }

                                    .price-tax {
                                      font-size: 10px;
                                      font-weight: 400;
                                      letter-spacing: 1.25px;
                                      color: $text-gray;
                                      text-align: right;
                                      .to {
                                        font-size: 8px;
                                      }

                                      @include sp-screen {
                                        padding-left: 6px;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .no-results{
                .no-hits-title{
                  font-size: 28px;
                }
                .no-hits-text{
                  font-size: 18px;
                  margin: {
                    top: 30px;
                    bottom: 25px;
                  }
                }
                .suggest-text{
                  font-size: 15px;
                }
                .suggest-url{
                  font-size: 15px;
                  a{
                    color:#1f1e1e;
                  }
                  a:hover{
                    color:#7e7e7e;
                  }
                }
              }
            }
          }
        }

        // ページネーション箇所
        .text-center {
          #instant-search-pagination-container {
            .ais-pagination {
              padding: 0;
              margin-bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;

              @include sp-screen {
                margin-top: 23px;
              }

              &--item {
                margin-bottom: 0;

                .ais-pagination--link {
                  padding: 0;
                  margin: 0 18px;
                  display: block;
                  font-size: 14px;
                  letter-spacing: 1.87px;
                  color: $text-gray;

                  @include sp-screen {
                    margin: 0 3.5vw;
                  }
                }
              }

              &--item__active {
                .ais-pagination--link {
                  color: $text-black;
                  text-decoration: none;
                  position: relative;

                  &:before {
                    content: "";
                    width: 100%;
                    bottom: 4px;
                    display: block;
                    position: absolute;
                    border-bottom: 1px solid $text-black;
                  }
                }
              }

              &--item__first {
                .ais-pagination--link {
                  background: url("../images/arrow_first@2x.png");
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                  width: $algolia-pagination-arrow-width;
                  height: $algolia-pagination-arrow-height;
                }
              }

              &--item__previous {
                .ais-pagination--link {
                  background: url("../images/arrow_left@2x.png");
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                  width: $algolia-pagination-arrow-width;
                  height: $algolia-pagination-arrow-height;
                }
              }

              &--item__next {
                .ais-pagination--link {
                  background: url("../images/arrow_right@2x.png");
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                  width: $algolia-pagination-arrow-width;
                  height: $algolia-pagination-arrow-height;
                }
              }

              &--item__last {
                .ais-pagination--link {
                  background: url("../images/arrow_last@2x.png");
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                  width: $algolia-pagination-arrow-width;
                  height: $algolia-pagination-arrow-height;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ais-refinement-list--list {
  display: flex;
  flex-direction: column;

  .ais-refinement-list--checkbox {
    display: none;
  }
}

.ais-hierarchical-menu--list {
  display: flex;
  flex-direction: column;
}

.ais-root__collapsed .ais-body,
.ais-root__collapsed .ais-footer,
.ais-root__hide {
  display: none;
}

#algolia-autocomplete-container {
  @include sp-screen {
    display: none;
  }

  .aa-dropdown-menu {
    font-size: 14px;

    .col9{
      .aa-dataset-products {
        margin-bottom: 40px;
      }

      .algoliasearch-autocomplete-category {
        display: none;
      }
    }
  }
}
