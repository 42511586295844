.sofa-page {
  display: flex;
  justify-content: space-between;

  @include md-screen {
    flex-direction: column;
  }

  &__category {
    width: 24%;
    max-width: 180px;

    @include md-screen {
      width: 100%;
      max-width: none;
      margin: 0 auto 26px;
    }

    @include sp-screen {
      width: calc(100% - 32px);
    }

    &__title {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2.15px;
      margin-top: 0;
      margin-bottom: 24px;

      @include sp-screen {
        letter-spacing: 2.04px;
        margin-bottom: 16px;
      }
    }

    &__list-wrapper {
      .section {
        // 押した時に矢印を回転する
        .active {
          .toggle {
            &-arrow {
              &:before {
                transform: rotate(135deg);
                top: 6px;
              }
            }
          }
        }

        &__top {
          pointer-events: auto;
          cursor: pointer;

          .title {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 2.04px;
            margin-top: 0;
            margin-bottom: 11px;

            @include sp-screen {
              margin-bottom: 6px;
            }
          }

          .toggle {
            position: relative;
            display: block;
            width: 32px;
            height: 32px;
            margin-right: 65px;

            @include md-screen {
              margin-right: 0;
            }

            @include sp-screen {
              width: auto;
              width: 27px;
              height: 27px;
            }

            &-arrow {
              display: flex;
              align-items: center;
              position: absolute;
              right: 0;
              height: 32px;

              &:before {
                content: "";
                width: 5px;
                height: 5px;
                border: 0;
                border-top: solid 1px $products-slider__deep-gray;
                border-right: solid 1px $products-slider__deep-gray;
                transform: rotate(45deg);
                position: absolute;
                right: 2px;
                top: 7px;
              }
            }
          }
        }

        &__bottom {
          display: none;

          .list {
            margin-bottom: 24px;

            @include sp-screen {
              margin-bottom: 12px;
            }

            &__item {
              margin-bottom: 12px;

              a {
                color: $text-black;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 1.85px;
                padding-left: 40px;
                position: relative;

                &:before {
                  content: "";
                  display: inline-block;
                  width: 6px;
                  height: 1px;
                  background: $text-black;
                  position: absolute;
                  left: 27px;
                  top: 8px;
                }
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  &__slider {
    width: 76%;
    max-width: 820px;

    @include md-screen {
      width: 100%;
      max-width: none;
    }

    &__container {
      width: 100%;
      display: flex;
      height: 363px;
      margin-bottom: 41px;

      &:last-child {
        margin-bottom: 0;
      }

      @include sp-screen {
        flex-direction: column;
        height: auto;
        margin-bottom: 35px;
      }

      .slider {
        width: 100%;
        max-width: 622px;
        height: 100%;
        overflow: hidden;

        @include md-screen {
          max-width: none;
        }

        @include sp-screen {
          height: 61.5vw;
        }

        &__list {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;

          .owl-stage-outer {
            width: 100%;
            height: 100%;

            .owl-stage {
              display: flex;
              height: 100%;

              .owl-item {
                width: auto;
              }
            }
          }

          .owl-nav {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            margin: 0;
            bottom: 2.5px;

            button {
              background: transparent;
              border: 0;
              padding: 0;
            }

            .owl-prev {
              &:before {
                content: "";
                width: 9px;
                height: 9px;
                border: 0px;
                border-top: solid 2px $text-white;
                border-right: solid 2px $text-white;
                transform: rotate(-135deg);
                position: absolute;
                top: 50%;
                left: 13px;
                margin-top: -4px;

                @include sp-screen {
                  left: 30px;
                }
              }
            }

            .owl-next {
              &:before {
                content: "";
                width: 9px;
                height: 9px;
                border: 0;
                border-top: solid 2px $text-white;
                border-right: solid 2px $text-white;
                transform: rotate(45deg);
                position: absolute;
                top: 50%;
                right: 13px;
                margin-top: -4px;

                @include sp-screen {
                  right: 30px;
                }
              }
            }
          }

          .owl-dots {
            position: absolute;
            bottom: 8px;

            .owl-dot {
              background: transparent;
              border: 0;
              padding: 0;
            }
          }

          &__item {
            max-width: 622px;
            height: 100%;
            display: block;
            background-position: center;
            background-size: cover;

            @include md-screen {
              max-width: none;
            }
          }
        }
      }

      .content {
        width: 100%;
        max-width: 198px;
        height: 100%;
        background: $background-light-gray;

        @include sp-screen {
          max-width: none;
          background: transparent;
        }

        &__image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
