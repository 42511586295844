@mixin selector-box {
  height: 40px;
  border: 1px solid $button__deep-gray;
  padding: 0 12px;
}

@mixin selector-separator {
  content: "";
  height: 40px;
  border-left: 1px solid $text-black;
  position: absolute;
  top: 0;
  right: 38px;
}

@mixin selector-arrow-icon {
  content: "";
  @include arrow-icon($t: 15px, $r: 16px);
}

@mixin selector-separator-arrow {
  &::before {
    @include selector-separator;
  }
  &::after {
    @include selector-arrow-icon;
  }
}

#checkout {
  .actions-toolbar {
    .action:last-child {
      margin-bottom: 24px;
    }
    @include sp-screen {
      > .primary, > .secondary {
        margin: 0 10px;
      }
    }
  }
  .opc-wrapper {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
    float: none;
  }
  .checkout-payment-method {
    .payment-methods {
      @include sp-screen {
        margin: 0;
      }
    }
    .payment-method-content {
      padding: 0;
    }
    .step-title {
      background-color: $cart-order-page__title-section;
      height: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding: 0 16px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.92px;
    }
    .payment-method {
      p {
        font-size: 11px;
        margin: 0 14px 20px 14px;
      }
    }
  }
  .form-veritegmo-cc {
    @include sp-screen {
      margin: 10px;
    }
  }
  .veritegmo_cc {
    > .field > .control {
      position: relative;
      margin: 16px 0;
    }
    select, .input-text {
      @include selector-box;
    }
    .field-tooltip {
      top: 6px;
    }
    .field {
      &:before {
        display: none;
      }
      .label {
        margin: 16px 0;
        @include sp-screen {
          margin-bottom: 0;
        }
      }
      &.registered > .control {
        @include selector-separator-arrow;
      }
      &.year {
        .select {
          width: 100px;
        }
        > .control {
          @include selector-separator-arrow;
        }
      }
      &.month {
        .select {
          width: 120px;
        }
        > .control {
          @include selector-separator-arrow;
        }
      }
      &.option-param > .control {
          @include selector-separator-arrow;
      }
      &.type {
        margin: 0;
        > .control {
          width: 65%;
          margin-left: 35%;
          margin-bottom: 0;
          @include sp-screen {
            margin-left: 0;
          }
        }
      }
      &.required > label:after {
        position: relative;
        top: auto;
      }
    }
    > .field:not(.choice) {
      > .label {
        padding: 12px 15px 0 0;
        width: 35%;
        @include sp-screen {
          margin-top: 0;
          width: 100%;
        }
      }
      > .control {
        width: 65%;
        @include sp-screen {
          width: 100%;
          max-width: 400px;
          margin-top: 5px
        }
      }
      &.cvv {
        > .control {
          width: 100px;
          margin-right: 200px;
        }
        @include sp-screen {
          .field-tooltip, .field-tooltip-content::before, .field-tooltip-content::after {
            margin-top: 0;
          }
          .field-tooltip-content {
            width: 200px;
          }
        }
      }
    }
    .input-text {
      &.cvv {
        width: 60px;
      }
    }
  }
}

//
//  配送設定画面
//  _____________________________________________

// 共通
.form.order {
  width: 100%;
  max-width: 560px;
  margin: 0 auto;

  .shipping-address {
    .block-title {
      background-color: $cart-order-page__title-section;
      height: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding: 0 16px;

      strong {
        font-size: 12px;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: 0.92px;
      }
    }

    .block-shipping {
      margin-bottom: 32px;

      @include sp-screen {
        padding: 0 16px;
      }

      .block-content {
        .box-shipping-address {
          .box-content {
            display: flex;
            flex-wrap: wrap;

            input[type="radio"] {
              appearance: none;
              width: 100%;
              max-width: 16px;
              height: 16px;
              border: 1px solid $button__light-gray;
              border-radius: 100%;
              background: transparent;
              margin: 4px 18px 0 0;
            }
            input[type="radio"]:checked {
              width: 100%;
              max-width: 16px;
              height: 16px;
              border: 1px solid $button__light-gray;
              border-radius: 100%;
              background: transparent;
              position: relative;
              margin: 4px 18px 0 0;

              &:before {
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background: $button__deep-gray;
                display: block;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                position: absolute;
              }
            }

            address {
              font-size: 14px;
              line-height: 1.5;
              letter-spacing: 2.04px;
              max-width: 197px;
            }

            .secondary {
              width: 100%;
              display: block;
              padding-top: 14px;
              padding-left: 34px;

              button {
                border: 1px solid $button__light-gray;
                border-radius: 0;
                background: transparent;
                width: 100%;
                max-width: 137px;
                height: 34px;
                padding: 0;

                span {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 1;
                  letter-spacing: 2.04px;
                }
              }
            }
          }
        }
      }
    }

    .actions {
      .secondary {
        display: flex;
        margin-bottom: 32px;

        @include sp-screen {
          width: calc(100% - 32px);
          margin: 0 auto 32px;
        }

        button {
          border: 1px solid $button__light-gray;
          border-radius: 0;
          background: transparent;
          width: 100%;
          max-width: 220px;
          height: 34px;
          padding: 0;

          span {
            font-size: 14px;
            font-weight: normal;
            line-height: 1;
            letter-spacing: 2.04px;
          }
        }
      }
    }
  }

  .orders {
    .block-title {
      background-color: $cart-order-page__title-section;
      height: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding: 0 16px;

      strong {
        font-size: 12px;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: 0.92px;
      }
    }

    .order {
      margin-bottom: 24px;

      @include sp-screen {
        padding: 0 16px;
      }

      .block-title {
        background-color: transparent;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        height: auto;

        strong {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 2.04px;
          padding-bottom: 16px;
        }

        textarea {
          height: 120px;
          border: 1px solid $button__light-gray;
          resize: none;
        }
      }

      .block-shipping {
        .shipping-provider {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .product-item-details {
          display: block;
          width: auto;
          padding: 0 16px 24px;
          background: $cart-order-page__content-section;

          // 共通指定
          tr {
            display: block;

            th {
              padding: 0;
              border-top: 0;
              border-bottom: 0;
            }

            td {
              padding: 0;
              border-top: 0;
              border-bottom: 0;
            }
          }

          // 個別指定
          thead {
            display: block;

            tr {
              display: block;
              position: relative;

              th {
                display: flex;
                flex-wrap: wrap;
                position: relative;

                .item-name {
                  display: block;
                  width: 100%;
                  padding-top: 16px;

                  .name-private {
                    display: none;
                  }

                  .product-name {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.5;
                    letter-spacing: 0.92px;
                  }
                }

                .simple-info {
                  display: inline-block;
                  padding-right: 8px;
                  padding-top: 16px;
                  padding-bottom: 16px;
                  max-height: 48px;

                  &:after {
                    content: "";
                    width: 100%;
                    display: block;
                    position: absolute;
                    border-bottom: 1px dashed $static-border-gray;
                    bottom: 0;
                  }

                  .simple-thumbnail {
                    display: inline-block;

                    img {
                      width: 48px;
                      height: 48px;
                    }
                  }
                }
              }

              td {
                .simple-item {
                  position: absolute;
                  right: 0;
                  bottom: 16px;

                  .product-price {
                    .price {
                      font-size: 14px;
                      font-weight: 500;
                      letter-spacing: 1.07px;
                    }
                  }
                }
              }
            }
          }

          tbody {
            display: block;
            width: 100%;

            tr {
              position: relative;
              max-height: 48px;
              padding-top: 16px;
              padding-bottom: 16px;
              border-bottom: 1px dotted $static-border-gray;

              th {
                display: flex;
                max-height: 48px;

                .simple-info {
                  display: inline-block;
                  padding-right: 8px;

                  .simple-thumbnail {
                    display: inline-block;

                    img {
                      width: 48px;
                      height: 48px;
                    }
                  }
                }

                .item-name {
                  flex: 9;
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: 0.31px;
                }
              }

              td {
                display: block;
                position: relative;

                .item-price {
                  text-align: right;
                  position: absolute;
                  right: 0;
                  bottom: 0;

                  .product-price {
                    .price {
                      font-size: 14px;
                      font-weight: 500;
                      letter-spacing: 1.07px;
                    }
                  }
                }
              }
            }

            .item-option {
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 0.31px;
            }
          }

          tfoot {
            display: block;
            width: 100%;

            tr {
              td {
                display: block;
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: 0;

                span {
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: 1.74px;
                }
              }
            }

            .col.parent-item.qty {
              .col.parent-item.qty {
                text-align: right;
                padding-top: 16px;
                padding-bottom: 14px;
                border-bottom: 1px solid $button__deep-gray;
              }
            }

            .assembly {
              display: none;
              justify-content: space-between;
              align-items: center;
              padding-top: 16px;
              padding-bottom: 16px;
              border-bottom: 1px solid $button__deep-gray;

              th {
                span {
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: 1.74px;
                }
              }

              td {
                position: relative;

                &:before {
                  content: "";
                  height: 40px;
                  border-left: 1px solid $text-black;
                  position: absolute;
                  top: 0;
                  right: 38px;
                }

                &:after {
                  content: "";
                  @include arrow-icon($t: 15px, $r: 16px);
                }

                select {
                  width: 200px;
                  height: 40px;
                  border: 1px solid $button__deep-gray;
                  padding: 0 50px 0 12px;
                }
              }
            }
          }
        }

        .payment {
          margin-top: -24px;
          background: $cart-order-page__content-section;

          tbody {
            display: flex;
            flex-direction: column;
            padding: 0 16px;

            .delivery-date {
              border-bottom: 0;
              order: -2;
              position: relative;

              &:after {
                content: "";
                width: 100%;
                border-bottom: 1px dotted $static-border-gray;
                position: absolute;
                bottom: 0;
              }

              th {
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 1.74px;
              }

              td {
                padding: 16px 0;

                .inputdate {
                  position: relative;

                  &:before {
                    content: "";
                    height: 40px;
                    border-left: 1px solid $text-black;
                    position: absolute;
                    top: 0;
                    right: 38px;
                  }

                  &:after {
                    content: "";
                    @include arrow-icon($t: 15px, $r: 16px);
                  }

                  input {
                    // JSから幅が制御されているのでimportant指定しました
                    width: 169px !important;
                    height: 40px;
                    border: 1px solid $button__deep-gray;
                    padding: 0 50px 0 12px;
                  }
                  button {
                    display: none;
                  }
                }
              }
            }

            .delivery-time {
              margin-bottom: 12px;
              border-bottom: 1px solid $button__deep-gray;
              order: -1;

              th {
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 1.74px;
              }

              td {
                padding: 16px 0;
              }

              .notpad {
                position: relative;

                &:before {
                  content: "";
                  height: 40px;
                  border-left: 1px solid $text-black;
                  position: absolute;
                  top: 16px;
                  right: 38px;
                }

                &:after {
                  content: "";
                  @include arrow-icon($t: 31px, $r: 16px);
                }

                select {
                  width: 169px;
                  height: 40px;
                  border: 1px solid $button__deep-gray;
                  padding: 0 50px 0 12px;
                }
              }
            }

            .discount {
              th, .price {
                color: $text-red;
              }
            }

            .total {
              padding: 12px 0 16px;
              margin-top: 12px;
              border-top: 1px solid $static-border-gray;

              th {
                font-size: 14px;
              }

              .price {
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 1.53px;
              }
            }

            tr {
              display: flex;
              justify-content: space-between;
              align-items: center;

              th {
                padding: 4px 0 2px;
                font-weight: 400;
                letter-spacing: 2.04px;

                span {
                  font-size: 12px;
                }

                .tax {
                  font-size: 10px;
                }
              }

              td {
                padding: 4px 0 2px;

                .price {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 0.88;
                  letter-spacing: 1.22px;
                }
              }
            }
          }
        }
      }
    }
  }

  // 要らない空白を削除
  .table {
    &:not(.cart) {
      &:not(.totals) {
        tfoot {
          > tr {
            &:first-child {
              th,
              td {
                border-top: 0;
              }
            }
          }
        }
      }
    }
  }

  .actions-toolbar {
    @include sp-screen {
      padding: 0 16px;
    }
  }
}

.box-order-summery {
  .fieldset {
    .order-summary {
      margin-bottom: 24px;

      tbody {
        &:before {
          content: '注文合計';
          display: flex;
          align-items: center;
          height: 30px;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 0.92;
          padding: 0 16px;
          margin-top: 16px;
          margin-bottom: 20px;
          background-color: $cart-order-page__title-section;
        }

        tr {
          display: flex;
          justify-content: space-between;
          padding: 0 16px;

          @include sp-screen {
            margin: 0 16px;
          }

          th {
            padding: 4px 0 2px;
            font-weight: 400;
            letter-spacing: 2.04px;



            span {
              font-size: 12px;
            }

            .tax {
              font-size: 10px;
              }
          }

          td {
            padding: 4px 0 2px;
            font-size: 12px;
            font-weight: 500;
            line-height: 0.88;
            letter-spacing: 1.43px;
          }
        }

        .discount {
          th, .price {
            color: $text-red;
          }
        }

        .total {
          padding: 12px 0 16px;
          margin: 12px 16px 0;
          border-top: 1px solid $static-border-gray;

          @include sp-screen {
            margin: 12px 32px 0;
          }

          .price {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 1.53px;
          }
        }
      }
    }
  }
}

//
//  ご注文内容の確認画面
//  _____________________________________________

.form.order.order-review {
  .billing-address {
    .block-title {
      background-color: $cart-order-page__title-section;
      height: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding: 0 16px;

      strong {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.92px;
      }
    }

    .block-shipping {
      margin-bottom: 32px;

      @include sp-screen {
        padding: 0 16px;
      }

      .block-content {
        .box-billing-address {
          .box-content {
            address {
              font-size: 14px;
              line-height: 1.5;
              letter-spacing: 2.04px;
              max-width: 197px;
            }
          }
        }
      }
    }
  }

  .orders {
    .order {
      .block-shipping {
        .product-item-details {
          tfoot {
            tr {
              td {
                span {
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 2.04px;
                }
              }
            }

            .col.parent-item.qty {
              .col.parent-item.qty {
                span {
                  font-size: 12px;
                }
              }
            }

            .col.parent-item.price {
              .col.parent-item.price {
                border-bottom: none;
              }
            }

            .assembly {
              border-bottom: none;

              td {
                &:before {
                  display: none;
                }

                &:after {
                  display: none;
                }
              }
            }
          }
        }

        .payment {
          background: $cart-order-page__content-section;

          tbody {
            padding: 0 16px;

            .delivery-date {
              background: transparent;
              padding: 0;

              &:after {
                width: 100%;
              }

              th {
                font-size: 14px;
                letter-spacing: 2.04px;
              }

              td {
                font-size: 14px;
                font-weight: 600;
                line-height: 1.5;
                letter-spacing: 1.25px;
              }
            }

            .delivery-time {
              background: transparent;
              padding: 0;
              border-bottom: 1px solid $button__deep-gray;

              th {
                font-size: 14px;
                letter-spacing: 2.04px;
              }

              td {
                font-size: 14px;
                font-weight: 600;
                line-height: 1.5;
                letter-spacing: 1.25px;
              }

              .notpad {
                &:before {
                  display: none;
                }

                &:after {
                  display: none;
                }
              }
            }

            .super.total {
              td {
                .price {
                  font-size: 20px;
                  font-weight: 600;
                  letter-spacing: 1.53px;
                }
              }
            }
          }
        }
      }
    }
  }

  .box-payment-method {
    .fieldset {
      margin-bottom: 24px;

      @include sp-screen {
        margin-bottom: 40px;
      }

      .block-title {
        background-color: $cart-order-page__title-section;
        height: 30px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 0 16px;

        strong {
          font-size: 12px;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 0.92px;
        }
      }

      .box-content {
        font-size: 12px;
        letter-spacing: 1.21px;
        padding: 0 16px;

        @include sp-screen {
          margin: 0 16px;
        }
      }
    }
  }
}

//
//  注文完了画面
//  _____________________________________________

.order-checkout-success {
  .page-title-wrapper {
    text-align: center;
    padding-top: 40px;

    .page-title {
      margin-top: 0;
      margin-bottom: 24px;

      .base {
        font-size: 18px;
        font-weight: 500;
        line-height: 0.94;
        letter-spacing: 1.38px;
      }
    }
  }
  .success {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;

    @include sp-screen {
      width: calc(100% - 32px);
    }

    .actions-toolbar {
      .primary {
        .success-message {
          margin-bottom: 32px;

          .success-message--item {
            display: block;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: 0.85px;

            .success-message--item-link {
              color: #7b7b7b;
            }
          }
        }

        a.back-to-home {
          display: flex;
          background: transparent;
          border: 1px solid $button__deep-gray;

          span {
            color: $text-black;
          }
        }
      }
    }
  }
}
