@mixin md-screen {
    @media (max-width: 992px) { @content; } 
}

@mixin sp-screen {
    @media (max-width: 767px) { @content; } 
}

@mixin pc-screen {
    @media (min-width: 768px) { @content; } 
}