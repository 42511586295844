//
//  カート画面
//  _____________________________________________

// 全体要素のレイアウト
.cart-container {
  width: 100%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @include sp-screen {
    width: calc(100% - 32px);
  }

  .form-cart {
    width: 100%;

    .table-wrapper {
      margin-bottom: 40px;

      .col {
        padding: 0;
      }
      .items {
        thead + .item {
          border-top: none;
        }
      }
      .product-item-photo {
        padding-right: 12px;
        max-width: 80px;
      }
    }
    .actions {
      .action.update {
        margin: 0 auto 32px;

        &:before {
          display: none;
        }
      }
      button {
        width: 100%;
        height: 48px;
        border: none;
        border-radius: 2px;
        background-color: $button__light-gray;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 1.89px;
        color: $text-white;

        span {
          color: $text-white;
        }
      }
    }
  }
  .cart-summary {
    width: 100%;
    position: static;
    background: transparent;
    padding: 0;
    margin-bottom: 0;

    .cart-delivery {
      display: none;
    }
    .cart-totals {
      border-top: none;
      padding-top: 0;

      .table-wrapper {
        margin-bottom: 32px ;

        table {
          tbody {
            tr.totals.sub {
              display: flex;
              align-items: center;
              justify-content: space-between;

              th {
                padding: 0;
                font-size: 13px;
                font-weight: normal;
                line-height: 1.3;
                letter-spacing: 0.99px;
              }
              td {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 0;

                .price {
                  font-size: 24px;
                  font-weight: 500;
                  line-height: 0.7;
                  letter-spacing: 1.83px;
                }

                .tax {
                  color: $text-gray;
                  font-size: 12px;
                  letter-spacing: 0.9px;
                  padding: 9px 0 0 6px;
                }
              }
            }
            .delivery-charge {
              td {
                padding: 6px 0;
                text-align: right;

                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    .cart-userguide {
      .userguide {
        background-color: $cart-order-page__content-section;
        padding: 30px 40px;

        @include sp-screen {
          padding: 30px 20px;
        }

        .userguide-content {
          margin-bottom: 23px;

          .userguide-title {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 2.04px;
            margin-bottom: 18px;
          }

          .userguide-content {
            font-size: 12px;
            font-weight: 600;
            line-height: 1.8;
            letter-spacing: 1.74px;
            margin-bottom: 0;
          }
        }
      }

      .agreement_pattern_list {
        .pattern_items {
          margin-bottom: 0;

          .pattern_item {
            padding-top: 23px;
            margin-bottom: 23px;
            border-top: 1px solid #333333;

            &-title {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.5;
              letter-spacing: 2.04px;
              margin-bottom: 18px;
            }

            &-body {
              font-size: 12px;
              line-height: 1.8;
              letter-spacing: 1.74px;

              li {
                margin-bottom: 15px;
                letter-spacing: 0.3px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      .agreement {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 8px;

        .agreement-check {
          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 0.86;
          }
        }
      }
    }
    .checkout-methods-items {
      .item {
        margin-top: 40px;
        border-radius: 2px;
        background-color: $button__deep-gray;

        .ordercheckout {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 48px;
          text-decoration: none;

          span {
            font-size: 13px;
            letter-spacing: 1.89px;
            color: $text-white;
          }
        }
      }
    }
  }
}
.column.main {
  .product-item {
    padding-left: 0;
  }

  .cart-empty {
    @include sp-screen {
      width: calc(100% - 32px);
      margin: 0 auto;
    }
  }
}

// 商品情報
#shopping-cart-table {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.31px;
  line-height: 1.5;

  thead {
    tr {
      th {
        padding-bottom: 11px;

        span {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 2.04px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0;

        .product-item-details {
          display: block;
          width: 100%;

          dl {
            margin-bottom: 16px;
          }

          .product-item-name {
            letter-spacing: 0.92px;
            margin: 16px 0;

            a:hover {
              text-decoration: none;
            }

            a:active {
              text-decoration: none;
            }

            .name-private {
              display: none;
            }
          }

          .config-info {
            display: flex;
            height: 48px;

            .fabric-image {
              margin-right: 8px;

              img {
                max-width: 48px;
                height: 48px;
              }
            }

            .config-description {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              position: relative;

              .fabric-label {
                width: 100%;
              }

              .product-price {
                display: flex;
                position: absolute;
                right: 0;
                bottom: 0;
              }
            }
          }

          .item-option {
            padding-bottom: 16px;
            border-bottom: 1px dashed $products-slider__light-gray;
          }

          .assembly {
            position: relative;
            padding-bottom: 16px;
            border-bottom: 1px dashed $products-slider__light-gray;

            &:before {
              content: "";
              height: 38px;
              border-left: 1px solid $text-black;
              position: absolute;
              bottom: 17px;
              right: 38px;
            }

            &:after {
              content: "";
              @include arrow-icon($b: 33px, $r: 16px);
            }
          }

          .product-edit {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .product-assembly-option {
              width: 200px;
              height: 40px;
              padding: 0 38px 0 12px;
              border: 1px solid $text-black;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 1.22px;
            }

            .product-qty {
              width: 100%;
              display: flex;
              justify-content: flex-end;

              .field {
                .control {
                  display: flex;
                  border: 1px solid $button__deep-gray;
                  width: 100%;
                  max-width: 118px;

                  button {
                    border: 0;
                    padding: 0;
                    border-radius: 0;
                    background: transparent;
                    box-shadow: none;
                    width: 39px;
                    height: 38px;
                    display: inline-block;
                    position: relative;

                    .decrement-arrow {
                      &:before {
                        content: "";
                        @include arrow-icon($t: 15px, $l: 17px);
                      }
                    }

                    .increment-arrow {
                      &:before {
                        content: "";
                        @include arrow-icon($tf: -45deg, $t: 19px, $l: 16px);
                      }
                    }

                    &:focus {
                      padding: 0;
                    }
                    &:active {
                      padding: 0;
                    }
                  }

                  input {
                    width: 40px;
                    height: 38px;
                    text-align: center;
                    padding: 0;
                    border-top: 0;
                    border-bottom: 0;
                    border-right: 1px solid $button__deep-gray;
                    border-left: 1px solid $button__deep-gray;
                    font-size: 24px;
                    letter-spacing: 1.83px;
                    font-family: minion-pro, dnp-shuei-mincho-pr6n, sans-serif, serif;
                    font-weight: normal;
                    color: $text-black;
                  }
                }
              }
            }

            .product-delete {
              width: 100%;
              max-width: 26px;
              margin-left: 54px;

              .actions-toolbar {
                a {
                  padding: 0;
                  margin-bottom: 0;
                  border: 0;
                  position: relative;
                  background: transparent;
                  box-shadow: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;

                  &:before {
                    content: "";
                    width: 100%;
                    border-bottom: 1px solid $text-blue;
                    position: absolute;
                    bottom: -3px;
                  }

                  span {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.04;
                    letter-spacing: 0.92px;
                    color: $text-blue;
                  }
                }
              }
            }
          }

          .product-subtotal {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .product-subtotal-title {
              font-size: 16px;
              font-weight: 600;
              margin-right: 12px;
            }

            .subtotal-info {
              display: flex;
              align-items: center;

              .price-excluding-tax {
                font-size: 16px;
              }

              .cart-tax {
                font-size: 10px;
              }
            }
          }

          .delivery-date-range {
            display: flex;
            justify-content: flex-end;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.58px;
          }

          .item-name {
            display: flex;
            padding-bottom: 16px;
            border-bottom: 1px dashed $products-slider__light-gray;

            .bundle-thumbnail {
              max-width: 128px;
              max-height: 128px;

              img {
                max-width: 100%;
                height: auto;
              }
            }

            .bundle-description {
              display: flex;
              flex-wrap: wrap;
              flex: 9;

              .bundle-label {
                display: none;
              }

              .product-name {
                width: 100%;
                padding-left: 12px;
              }

              .product-price {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              }
            }
          }

          .simple-info {
            display: flex;
            justify-content: space-between;
            padding-bottom: 16px;
            border-bottom: 1px dashed $products-slider__light-gray;

            .simple-thumbnail {
              max-width: 128px;
              max-height: 128px;

              img {
                max-width: 100%;
                height: auto;
              }
            }

            .simple-description {
              position: relative;
              flex: 9;

              .product-price {
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
.price-excluding-tax {
  font-size: 14px;
  letter-spacing: 1.07px;
}
.cart-tax {
  font-size: 10px;
  letter-spacing: 0.9px;
  color: $text-gray;
  padding: 5px 0 0 8px;
  line-height: 1;
}
