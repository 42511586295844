// footerレイアウト
footer * {
  color: $footer-text-white;
  font-size: 12px;
  letter-spacing: 0.07px;
  line-height: 1;
}

.page-footer {
  background-color: $footer-background-gray;
  margin-top: 104px;

  @include sp-screen {
    margin-top: 64px;
  }

  .footer.content {
    border: 0;
    margin: 0;
    padding: 0;

    .bottom-menu-sp {
      position: fixed;
      z-index: 999;
      bottom: 0;
      width: 100%;
      height: 58px;
      background-color: $footer-background-gray;
      display: none;

      @include sp-screen {
        display: block;
        bottom: 0;
        transition: bottom 0.25s cubic-bezier(0.4, 0.0, 0.2, 1);
      }

      .items {
        display: flex;
        height: 100%;

        .item {
          width: calc(100% / 5);
          position: relative;

          &:after {
            content: "";
            position: absolute;
            border-right: 1px solid $text-white;
            height: 26px;
            right: 0;
            bottom: 16px;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }

          a {
            display: block;
            height: 100%;
          }

          .item-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            max-width: 32px;
            margin: 0 auto;

            img {
              width: 90%;
              padding-bottom: 4px;
            }

            span {
              font-size: 10px;
              font-weight: 400;
              line-height: 1;
              letter-spacing: 0.98px;
            }
          }
        }
      }
    }

    .bottom-menu--hidden {
      transition: bottom 0.200s cubic-bezier(0.4, 0.0, 0.2, 1);
    }

    .newsletter {
      display: none;
    }
    .switcher-store {
      display: none;
    }
    .links {
      display: none;
    }
    .copyright {
      display: none;
    }
  }

  .widget.block {
    margin: 0;
  }
}

.footer-container {
  width: calc(100% - 200px);
  margin: 0 auto;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  padding-top: 38px;
  padding-bottom: 21px;

  @include sp-screen {
    width: calc(100% - 32px);
    padding-top: 34px;
    padding-bottom: 97px;
  }
}

.footer-top {
  display: flex;
  padding-bottom: 30px;
  margin-bottom: 33px;
  border-bottom: 1px solid $footer-text-white;

  @include sp-screen {
    flex-direction: column;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.footer-bottom {
  display: flex;
  justify-content: space-between;

  @include sp-screen {
    flex-direction: column;
    padding-top: 24px;
  }
}

.footer-list-title {
  display: none;

  @include sp-screen {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 0.86;
    letter-spacing: 0.09px;
  }
}

.blank {
  @include sp-screen {
    border-bottom: none !important;
  }
}

.copyright-section {
  @include sp-screen {
    padding-top: 32px;
    border-bottom: none !important;
  }
}

// sectionごとに細かい要素
.section {
  flex: 1;
  position: relative;

  @include sp-screen {
    border-bottom: 1px solid $footer-text-white;
  }

  &__logo-container {
    margin-bottom: 25px;

    @include sp-screen {
      margin-bottom: 0;
    }
  }

  &__title-container {
    margin-bottom: 0;

    @include sp-screen {
      margin-bottom: 24px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    @include sp-screen {
      pointer-events: auto;
      position: relative;
    }

    .logo-container {
      height: 29px;

      @include sp-screen {
        height: auto;
      }

      .momo-logo {
        height: 29px;

        @include sp-screen {
          height: 35px;
        }
      }

      .rivergate-logo {
        height: 22px;
      }

      .pienikoti-logo {
        height: 20px;

        @include sp-screen {
          height: 21px;
        }
      }
    }

    .toggle {
      position: relative;
      display: none;

      @include sp-screen {
        display: block;
        width: 30px;
        height: 30px;
      }

      &-arrow {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        height: 30px;

        &:before {
          content: "";
          @include arrow-icon($arrow-color: $footer-text-white, $tf: 45deg, $w: 5px, $h: 5px, $r: 2px);
        }
      }
    }
  }

  &__bottom {
    display: block;

    @include sp-screen {
      display: none;
    }

    .rivergate-architects {
      margin-bottom: 15px;

      &-logo {
        height: 38px;
      }
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 23px;

      @include sp-screen {
        margin-bottom: 24px;
      }

      li {
        margin-bottom: 12px;
      }
    }

    .social-media {
      @include sp-screen {
        margin-bottom: 24px;
      }

      &__facebook {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 12px;
      }
      &__instagram {
        width: 24px;
        height: 24px;
        display: inline-block;
      }
    }
  }

  .company-name {
    margin-bottom: 13px;

    @include sp-screen {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 0.86;
      letter-spacing: 0.08px;
      margin-bottom: 24px;
    }
  }
  .copyright-section-list {
    list-style: none;
    padding-left: 0;

    @include sp-screen {
      text-align: center;
    }
  }

  .copyright {
    position: absolute;
    bottom: 12px;

    @include sp-screen {
      position: static;
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 1.45px;
    }
  }
}

// SPで閉じている時の高さ指定 (Toggle)
.momo {
  .section__top {
    @include sp-screen {
      padding-bottom: 26px;
    }
  }
}
.rivergate {
  .section__top {
    @include sp-screen {
      padding: 24px 0;
    }
  }
}
.pienikoti {
  .section__top {
    @include sp-screen {
      padding: 24px 0;
    }
  }
}

// SPで選択した時の矢印 (Toggle)
.active {
  .toggle {
    &-arrow {
      &:before {
        transform: rotate(135deg);
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
