//
//  Magentoから指定されているスタイルシートを調整
//  _____________________________________________

.fieldset {
  > .field,
  > .fields > .field {
    .field.choice {
      margin-bottom: 0;
    }
  }
}
.fieldset {
  margin: 0;
}
.field.option.required {
  margin: 0;
}
.field.option.option-holder {
  margin: 0;
}
.product-info-main .box-tocart {
  margin: 0;
}
.bundle-options-container .product-options-wrapper {
  margin: 0;
}

//
//  商品詳細のMain
//  _____________________________________________

// 全体レイアウト
#maincontent .columns .column .block {
  clear: both;
}

.catalog-product-view .column.main {
  @include md-screen {
    display: flex;
    flex-direction: column;
  }
}

.product-info-main {
  .page-title-wrapper {
    .page-title {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1.53px;
    }
  }
}

#product_addtocart_form {
  display: flex;
  flex-direction: column;

  .bundle-options-wrapper {
    width: 100%;

    .product-options-wrapper {
      width: 100%;

      @include sp-screen {
        width: calc(100% - 32px);
        margin: 0 auto;
      }

      .fieldset {
        @include sp-screen {
          padding: 0;
        }
      }
    }
  }

  .block-bundle-summary {
    width: 100%;
    position: static;

    @include sp-screen {
      width: calc(100% - 32px);
      margin: 0 auto;
    }
  }
}

.page-layout-1column {
  .product-info-main {
    max-width: 410px;
    overflow: hidden;

    @include md-screen {
      width: 100%;
      max-width: none;
    }
  }

  .product.media {
    width: 59%;
    max-width: 630px;

    @include md-screen {
      width: 100%;
      max-width: none;
      order: -1;
    }
  }
}

// SPEC箇所
#data-label-additional {
  font-size: 16px;
  font-weight: 600;
  line-height: 0.88;
  letter-spacing: 1.22px;
  margin: 0 0 16px 0;

  @include sp-screen {
    margin: 0;
    display: block;
    font-size: 18px;
    line-height: 1;
    width: 100%;
    padding: 5px 16px;
    background-color: $product-page__title-section;
    position: absolute;
    left: 0;
  }
}

#additional {
  margin: 0;
  padding: 0;
  border: none;

  @include sp-screen {
    padding-top: 40px;
  }
}

#product-attribute-specs-table {
  width: 100%;

  tbody {
    font-size: 14px;
    line-height: 1.17;
    letter-spacing: 0.92px;

    @include sp-screen {
      line-height: 1;
    }

    tr {
      display: flex;

      th {
        font-weight: normal;
        padding: 0 30px 16px 0;
        display: inline-block;
        width: 100%;
        max-width: 112px;

        @include sp-screen {
          padding: 0 30px 12px 0;
        }

        &:before {
          display: none;
        }
      }

      td {
        padding: 0 5px 16px 5px;
        display: inline-block;

        @include sp-screen {
          padding: 0 30px 18px 0;
        }

        &:before {
          display: none;
        }
      }
    }
  }
}

#data-label-product\.info\.description {
  margin: 0 0 6px 0;
  font-size: 12px;
  letter-spacing: 0.92px;
}

#product\.info\.description {
  margin-top: 0;
  margin-left: 0;
  border: 0;
  padding: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.92px;

  @include sp-screen {
    margin-bottom: 56px;
  }
}

// SERIES・SCENE箇所
.series-and-scene {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @include sp-screen {
    flex-direction: column;
    margin-top: 16px;
  }

  .item {
    width: calc(50% - 9px);

    @include sp-screen {
      width: 100%;
      padding-top: 24px;
    }

    .item-title {
      .title {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1.2px;
        margin-top: 0;
        margin-bottom: 14px;
        line-height: 1.5;

        @include sp-screen {
          margin-bottom: 6px;
        }
      }
    }

    .item-image {
      width: 100%;
      height: 189px;
      background-size: cover;
      background-position: center;

      @include sp-screen {
        height: 55.5vw;
      }
    }
  }
}

//
//  商品詳細の右側
//  _____________________________________________

.product-info-main {
  .product {
    max-width: none;
    margin-bottom: 17px;

    @include sp-screen {
      margin-bottom: 14px;
    }

    .page-title {
      margin-top: 0;
      margin-bottom: 0;

      span {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1.53px;
      }
    }

    .page-title-wrapper {
      ul {
        display: none;
      }
    }
  }

  .product-info-price {
    @include sp-screen {
      width: calc(100% - 32px);
      margin: 0 auto;
    }

    .product.attribute.overview {
      margin: 16px 0 0;
      font-size: 14px;

      .value {
        p {
          margin-bottom: 0;
        }
      }

      .product-price-list {
        .price-list-title {
          p {
            margin-bottom: 5px;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1.22px;
          }
        }

        .price-list {
          list-style: none;
          padding: 0;
          margin: 0;

          &__item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3px;

            .name {
              p {
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.92px;
                margin-bottom: 0;
              }
            }

            .price {
              p {
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.92px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .product-info-stock-sku {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .price-box {
        display: flex;
        align-items: center;
        margin-top: 0;

        // シンプル商品
        .price-container {
          margin: 0;
          font-size: 16px;

          .price-wrapper {
            .price {
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0.41px;

              .price-symbol {
                font-size: 14px;
              }
            }
          }
        }

        .price-from {
          display: flex;
          margin-bottom: 0;

          .price {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.41px;
          }
        }

        .price-tax {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.9px;
          color: $text-gray;
          padding:2px 0 0 6px;
        }

        .price-to {
          padding-top: 3px;
          font-size: 10px;
          margin: 0;
        }
      }

      .stock-box {
        display: flex;
        align-content: center;

        .available {
          margin: 0;
          padding-right: 16px;

          @include sp-screen {
            padding-right: 8px;
          }

          span {
            color: $text-red;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.41px;
          }
        }

        .type {
          margin: 0;

          span {
            color: $text-gray;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.41px;
          }
        }
      }
    }
  }

  .bundle-options-container {
    @include sp-screen {
      margin-bottom: 22px;
    }

    .block-bundle-summary {
      background: transparent;
      padding: 0;
    }
  }

  .media-mobile {
    @include sp-screen {
      width: calc(100% - 32px);
      margin: 0 auto;
    }
  }
}

.product-options-wrapper {
  .field {
    margin: 0;
  }

  .control {
    position: relative;

    select {
      border: 1px solid $button__deep-gray;
      padding: 8.5px 16px;
      height: 38px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.21px;
    }
    .bundle-option-select-wrap {
      position: absolute;
      margin-left: 8px;
      margin-top: 8px;
      width: 100%;
      height: 38px;
      z-index: 10;
    }
    .bundle-option-select {
      margin-left: 8px;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .show-bundle-option {
    border: 1px solid $button__deep-gray;
    padding: 0 12px;
    height: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.21px;
    }
    .name {
      width: 18vw;
      max-width: 270px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include md-screen {
        width: 50vw;
        max-width: none;
      }
    }

    .price-area {
      margin-top: 0;

      .price {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1.07px;
      }

      .tax {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.25px;
        color: $text-gray;
        padding-left: 4px;
      }
    }
  }

  .label {
    span {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.22px;
    }

    &:after {
      display: none;
    }
  }

  .fieldset {
    @include sp-screen {
      padding-left: 16px;
      padding-right: 16px;
    }

    .field {
      margin: 14px 0;
    }

    .fieldset-bundle-options {
      .option-holder {
        .control {
          .product-custom-option-wrapper:nth-child(1) {
            .label {
              margin-top: 40px;

              @include sp-screen {
                margin-top: 37px;
              }
            }
          }

          .product-custom-option-wrapper {
            width: 100%;
            position: relative;
            margin-top: 8px;
            margin-left: 8px;

            &:before {
              content: "";
              height: 38px;
              border-left: 1px solid $text-black;
              position: absolute;
              bottom: 0;
              right: 38px;
            }

            &:after {
              content: "";
              @include arrow-icon($b: 16px, $r: 16px);
            }

            .label {
              display: block;
              margin-top: 8px;
              font-size: 16px;
              font-weight: 600;
              line-height: 0.88;
              letter-spacing: 1.22px;
            }

            .product-custom-option {
              width: 100%;
              padding: 0 12px;
              height: 38px;
              border: 1px solid $text-black;
              margin-top: 16px;
            }
          }
        }
      }

      .field.option {
        margin: 30px 0 0;

        @include sp-screen {
          margin: 37px 0 0;
        }
      }

      .field.option:nth-of-type(1) {
        margin: 14px 0 0;
      }

      .field.option.option-holder {
        margin: 0;
      }

      .option {
        .control {
          display: flex;
          flex-wrap: wrap;
          margin-top: -8px;
          margin-left: -8px;

          // 並びを左寄せにするためbefore追加
          &:before {
            content: "";
            display: block;
            width: 9vw;
            max-width: 132px;
            order: 1;

            @include sp-screen {
              width: 28.6vw;
            }
          }

          .product-name {
            margin: 8px 0 0 8px;
            font-size: 14px;
          }

          .price-notice {
            margin: 8px 0 0;
            font-size: 14px;
            display: flex;

            .price-container {
              .price-wrapper {
                display: flex;

                .price {
                  letter-spacing: 0.41px;
                }
              }
            }
          }

          .choice {
            box-sizing: border-box;
            flex: 0 1 calc((100% / 3) - 8px);
            margin-top: 8px;
            margin-left: 8px;
            margin-bottom: 0;
            max-width: 131px;

            input {
              position: absolute;
              opacity: 0;
            }

            label {
              .bundle-option-label {
                .option-thumbnail {
                  cursor: pointer;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0;
                  border: 1px solid $button__deep-gray;

                  img {
                    vertical-align: top;
                  }

                  &:after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    display: block;
                    background-color: rgba(0, 0, 0, 0.34);
                    opacity: 0;
                    position: absolute;
                  }

                  &:hover {
                    &:after {
                      opacity: 1;
                    }
                  }
                }

                .option-info {
                  width: calc(100% - 16px);
                  max-width: 126px;
                  margin: 0 auto;
                  display: flex;
                  flex-direction: column;

                  @include md-screen {
                    margin: inherit;
                  }

                  @include sp-screen {
                    width: 100%;
                  }

                  .option-info-name {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.5;
                    letter-spacing: 0.92px;
                    padding-top: 5px;
                  }

                  .option-info-price-box {
                    padding-bottom: 16px;
                    display: flex;
                    align-items: center;

                    @include sp-screen {
                      padding-bottom: 8px;
                    }

                    .option-info-price {
                      .price {
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 1.07px;
                      }
                    }
                    .option-info-tax {
                      font-size: 10px;
                      font-weight: 400;
                      line-height: 1.2;
                      letter-spacing: 0.9px;
                      color: $text-gray;
                      padding: 3px 0 0 6px;
                    }
                  }
                }
              }
            }

            input:checked + label {
              .bundle-option-label {
                .option-thumbnail {
                  padding: 0;
                  &:after {
                    opacity: 1;
                    border: 2px solid $button__deep-gray;
                  }
                }
              }
            }
          }

          .nested {
            width: 100%;
          }

          .mage-error[generated] {
            margin-left: 8px;
          }
        }
      }

      .shoppinginfo.link {
        margin-top: 16px;

        @include sp-screen {
          margin-top: 32px;
        }
      }

      .mage-error[generated] {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2.04px;
        color: $text-red;
      }
    }
  }
}

.shoppinginfo {
  a {
    display: block;
    color: $text-blue;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.92px;
  }
}

.shipping-info-wrapper {
  position: relative;

  .fieldset {
    @include sp-screen {
      width: calc(100% - 32px);
      margin: 0 auto;
    }

    .shipping-info {
      margin-bottom: 16px;
      padding-top: 40px;

      @include sp-screen {
        margin-bottom: 40px;
      }

      .label {
        @include sp-screen {
          display: block;
          width: 100%;
          background-color: $product-page__title-section;
          padding: 5px 16px;
          position: absolute;
          left: 0;
        }

        span {
          display: block;
          font-size: 18px;
          font-weight: 600;
          line-height: 1;
          letter-spacing: 1.22px;
        }
      }
    }

    .box-shipping-rank {
      margin-bottom: 14px;

      .field {
        display: flex;
        align-items: center;

        .label-wrapper {
          width: 100%;
          max-width: 95px;
          display: inline-block;

          .label {
            display: flex;

            span {
              display: block;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 1.07px;
            }
          }
        }

        .product-shipping-rank-text {
          font-size: 14px;
          font-weight: 400;
        }

        .product-shipping-amount-text {
          font-size: 14px;
          font-weight: 400;
          padding-left: 7px;
        }
      }
    }

    .box-assembly {
      margin-bottom: 16px;

      .field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .label-wrapper {
          width: 100%;
          max-width: 95px;
          display: inline-block;

          .label {
            span {
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 1.07px;
            }
          }

          &:before {
            content: "";
            height: 38px;
            border-left: 1px solid $text-black;
            position: absolute;
            top: 0;
            right: 38px;
          }

          &:after {
            content: "";
            @include arrow-icon($t: 15px, $r: 16px);
          }
        }

        .product-assembly-option {
          padding: 0 50px 0 12px;
          width: 100%;
          max-width: 315px;
          height: 38px;
          border: 1px solid $text-black;
          font-size: 14px;

          @include sp-screen {
            max-width: 240px;
          }
        }
      }
    }

    .box-delivery-date {
      margin-top: 40px;
      margin-bottom: 16px;

      .field {
        display: flex;
        border-bottom: 1px solid $text-black;
        margin-bottom: 16px;

        span {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1.07px;
          padding-bottom: 2px;

          @include sp-screen {
            font-size: 14px;
          }
        }
      }

      .delivery-date-notice {
        p {
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 1.07px;

          @include sp-screen {
            letter-spacing: 0;
          }
        }
      }
    }
  }
}

.block-bundle-summary {
  .content {
    .bundle-summary {
      margin-top: 39px;

      .subtitle {
        margin-top: 0;
        margin-bottom: 13px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1.22px;
      }

      div[data-container="product-summary"] {
        ul {
          li {
            display: flex;
            margin-bottom: 14px;

            &:last-child {
              margin-bottom: 0;
            }

            span {
              font-size: 16px;
              line-height: 1.3em;
              letter-spacing: 1.22px;
            }

            .label {
              width: 90px;
              margin-right: 1em;
            }

            span[data-container="options"] {
              width: calc(100% - (90px + 1em));
            }
          }
        }
      }
    }

    .bundle-info {
      .product-details {
        .box-tocart {
          width: 100%;
          margin: 0;
          padding: 39px 0 0;

          .fieldset {
            .field {
              margin: 0;
              display: flex;
              align-content: center;
              justify-content: space-between;

              &:before {
                display: none;
              }

              &:after {
                display: none;
              }

              .qty {
                padding-right: 0;
                .control {
                  display: flex;
                  border: 1px solid $button__deep-gray;

                  button {
                    border: 0;
                    padding: 0;
                    border-radius: 0;
                    background: transparent;
                    box-shadow: none;
                    width: 34px;
                    height: 34px;
                    display: inline-block;
                    position: relative;

                    .decrement-arrow {
                      &:before {
                        content: "";
                        @include arrow-icon($t: 13px, $l: 14px);
                      }
                    }

                    .increment-arrow {
                      &:before {
                        content: "";
                        @include arrow-icon($tf: -45deg, $t: 15px, $r: 14px);
                      }
                    }

                    &:focus {
                      padding: 0;
                    }
                    &:active {
                      padding: 0;
                    }
                  }

                  input {
                    height: 34px;
                    text-align: center;
                    padding: 0;
                    border-top: 0;
                    border-bottom: 0;
                    border-right: 1px solid $button__deep-gray;
                    border-left: 1px solid $button__deep-gray;
                    font-size: 24px;
                    letter-spacing: 1.83px;
                    font-family: minion-pro, dnp-shuei-mincho-pr6n, sans-serif, serif;
                    font-weight: normal;
                    color: $text-black;
                  }
                }
              }

              .price-box {
                margin-top: 0;
                display: flex;
                align-items: center;

                .price-as-configured {
                  margin-bottom: 0;

                  .price {
                    font-size: 28px;
                    font-weight: 500;
                    letter-spacing: 0.72px;
                  }
                }

                .price-tax {
                  font-size: 10px;
                  line-height: 1.2;
                  letter-spacing: 0.9px;
                  color: $text-gray;
                  padding-left: 6px;
                }
              }
            }

            .actions {
              width: 100%;
              padding-top: 41px;
              display: flex;
              justify-content: space-between;

              .tocart {
                border: 0;
                padding: 0;
                border-radius: 0;
                background: $button__deep-gray;
                box-shadow: none;
                width: 100%;
                max-width: 197px;
                height: 48px;
                display: inline-block;
                margin-right: 16px;

                @include sp-screen {
                  margin-right: 8px;
                  height: 40px;
                }

                &:focus {
                  padding: 0;
                }
                &:active {
                  padding: 0;
                }

                span {
                  color: $text-white;
                  font-size: 16px;
                  font-weight: 600;
                  letter-spacing: 2.33px;
                  text-align: center;
                  display: block;

                  @include sp-screen {
                    font-size: 13px;
                    letter-spacing: 1.89px;
                  }
                }
              }

              .towishlist {
                border: 0;
                padding: 0;
                border-radius: 0;
                width: 100%;
                max-width: 197px;
                height: 48px;
                background: $button__light-gray;
                display: flex;
                align-items: center;
                justify-content: center;

                @include sp-screen {
                  height: 40px;
                }

                &:hover {
                  background: $button__light-gray;
                }

                span {
                  color: $text-white;
                  font-size: 16px;
                  font-weight: 600;
                  letter-spacing: 2.33px;

                  @include sp-screen {
                    font-size: 13px;
                    letter-spacing: 1.89px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//
//  シンプル商品対応
//  _____________________________________________

.box-tocart {
  width: 100%;
  margin: 0;
  padding-top: 39px;
  padding-bottom: 42px;

  @include sp-screen {
    padding-left: 16px;
    padding-right: 16px;
  }

  .fieldset {
    .field {
      margin: 0;
      display: flex;
      align-content: center;
      justify-content: space-between;

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }

      .qty {
        padding-right: 0;
        .control {
          display: flex;
          border: 1px solid $button__deep-gray;

          button {
            border: 0;
            padding: 0;
            border-radius: 0;
            background: transparent;
            box-shadow: none;
            width: 34px;
            height: 34px;
            display: inline-block;
            position: relative;

            .decrement-arrow {
              &:before {
                content: "";
                @include arrow-icon($t: 13px, $l: 14px);
              }
            }

            .increment-arrow {
              &:before {
                content: "";
                @include arrow-icon($tf: -45deg, $t: 15px, $r: 14px);
              }
            }

            &:focus {
              padding: 0;
            }
            &:active {
              padding: 0;
            }
          }

          input {
            width: 40px;
            height: 34px;
            text-align: center;
            padding: 0;
            border-top: 0;
            border-bottom: 0;
            border-right: 1px solid $button__deep-gray;
            border-left: 1px solid $button__deep-gray;
            font-size: 24px;
            letter-spacing: 1.83px;
            font-family: minion-pro, dnp-shuei-mincho-pr6n, sans-serif, serif;
            font-weight: normal;
            color: $text-black;
          }
        }
      }

      .price-box {
        margin-top: 0;
        display: flex;
        align-items: center;

        .price-as-configured {
          margin-bottom: 0;

          .price {
            font-size: 28px;
            font-weight: 500;
            letter-spacing: 0.72px;
          }
        }

        .price-tax {
          font-size: 10px;
          line-height: 1.2;
          letter-spacing: 0.9px;
          color: $text-gray;
          padding:7px 0 0 6px;
        }
      }
    }

    .actions {
      width: 100%;
      padding-top: 41px;
      display: flex;
      justify-content: space-between;

      .tocart {
        border: 0;
        padding: 0;
        border-radius: 0;
        background: $button__deep-gray;
        box-shadow: none;
        width: 100%;
        max-width: 197px;
        height: 48px;
        display: inline-block;
        margin-right: 16px;

        @include sp-screen {
          margin-right: 8px;
          height: 40px;
        }

        &:focus {
          padding: 0;
        }
        &:active {
          padding: 0;
        }

        span {
          color: $text-white;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 2.33px;
          text-align: center;
          display: block;

          @include sp-screen {
            font-size: 13px;
            letter-spacing: 1.89px;
          }
        }
      }

      .towishlist {
        border: 0;
        padding: 0;
        border-radius: 0;
        width: 100%;
        max-width: 197px;
        height: 48px;
        background: $button__light-gray;
        display: flex;
        align-items: center;
        justify-content: center;

        @include sp-screen {
          height: 40px;
        }

        &:hover {
          background: $button__light-gray;
        }

        span {
          color: $text-white;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 2.33px;

          @include sp-screen {
            font-size: 13px;
            letter-spacing: 1.89px;
          }
        }
      }
    }
  }
}
